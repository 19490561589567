import { routeConstants } from "services/keyConstants";

const baseUrl = "/production";
const baseMp = `/${routeConstants.MAM_OF_PRODUCT}`;

export const CREATER = `${baseUrl}/`;

export const GET_PRO_FORMAT_DATA = `${baseUrl}/pro-format/`;

export const GET_PRODUCT_DATA = `/product/`;

export const GET_PRODUCT_NAME = `/product/get-name`;

export const GET_FORMAT_DATA = `${baseUrl}/format/`;

export const UPDATE_REQUISITION = `${baseUrl}/format/update-requisition/`;

export const SPLIT_BMR = `${baseUrl}/bmr/split`;

export const GET_BMR_DATA = `${baseUrl}/bmr/`;

export const GET_BMR_YEAR_LIST=`${baseUrl}/bmr/year-list`

export const GET_FORMAT_YEAR_LIST=`${baseUrl}/format/year-list`


export const GET_BMR_DATA_BY_MONTH = `${baseUrl}/bmr/by-month`;

export const GET_STOCK_REGITSER_DATA = `${baseUrl}/stock-register`;

export const GET_FORMAT_DATA_BY_MONTH = `${baseUrl}/format/by-month`;

export const CREATE_PP = `${baseUrl}/format/create-pp`;

export const GET_ALLOTED_BMR = `${baseUrl}/format/get-history`;

export const GET_BMR_NUMBER = `${baseUrl}/format/get-bmr`;

export const CREATE_STOCK_REGISTER = `${baseUrl}/stock-register`;

export const GET_ALLOTED_REQUISITION_SLIP = `${baseUrl}/format/get-history`;

export const GET_PRODUCT_DETAIL = `${baseUrl}/format/get-previous`;

export const GET_ISSUE_DATA_BY_REQUISITION_ID = `${baseUrl}/bmr/issue-data/`;

export const DOWNLOAD_DOCUMENT = `${baseUrl}/format/download`;

export const UPDATE_SPECIFIC_BMR_HISTORY = `${baseUrl}/format/specific/`;

export const GET_CHILD_PRODUCT_DATA = `${baseUrl}/stock-register/by-product`;

export const GET_PRODUCT_OBJ = `/product/product-data`;

export const PROJECTION = `/projection`;

export const GET_ALL_PROCESS_DISTRIBUTION = `/customer-related-process-distribution/get-all`;

export const GET_BY_DOCUMENT_NAME = `/preventive-maintenance/get-by-document-name`;

export const GET_ISSUE_SLIP_FROM_PURCHASE = `${baseUrl}/bmr/get-issue-slip`;

export const GET_SELECTED_PRODUCT_LIST = `${baseMp}/get-list`;

export const DOWNLOAD_STOCK_REGISTER = `${baseUrl}/stock-register/download`;

export const DOWNLOAD_BMR = `${baseUrl}/bmr/download`;

export const CREATE_FINAL_SPLIT = `${baseUrl}/bmr/final-split`;
