import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

const DynamicTableCustomerFeedback = (props) => {
  const {
    columns,
    setColumns,
    rows,
    setRows,
    setErrors,
    errors,
    options,
    setOptions,
    isViewMode = false,
  } = props;

  const handleCheckboxChange = (category, option) => {
    setRows((prevState) => ({
      ...prevState,
      [category]: option,
    }));
  };

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
        Overall satisfaction of service
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableRow>
                <TableCell align="center" colSpan={options?.length || 1}>
                  Title
                </TableCell>
              </TableRow>

              {options?.length > 0 &&
                options?.map((option) => (
                  <TableCell key={option?.value} align="center">
                    {option?.name}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {columns?.map((category) => (
              <TableRow key={category?.value}>
                <TableCell>
                  <Typography variant="body1" fontWeight="bold">
                    {category?.name}
                  </Typography>
                </TableCell>
                {options?.map((option) => (
                  <TableCell key={option.value} align="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={isViewMode}
                          checked={rows[category?.value] === option?.value}
                          onChange={() =>
                            handleCheckboxChange(category?.value, option?.value)
                          }
                        />
                      }
                      label=""
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DynamicTableCustomerFeedback;
