import { commonRoutesConstants, routeConstants } from "services/keyConstants";

let baseUrl = `/${routeConstants.PROCESS_VALIDATIONS}`;

export const CREATE_PROCCESS_VALIDATION_PRO_FORMAT = `${baseUrl}/pro-format`;

export const GET_PROCCESS_VALIDATION_PRO_FORMAT = `${baseUrl}/pro-format`;

export const GET_PROCCESS_VALIDATION_PRO_FORMAT_BY_ID = `${baseUrl}/pro-format/`;

export const UPDATE_PROCCESS_VALIDATION_PRO_FORMAT_BY_ID = `${baseUrl}/pro-format/`;

export const UPDATE_PROCESS_VALIDATION_PRO_FORMAT_SPECIFIC = `${baseUrl}/pro-format/specific/`;

export const GET_PROCESS_VALIDATION_FORMAT_BY_ID = `${baseUrl}/format/by-proformatId`;

export const UPDATE_VALIDATION_PROTOCOL_REPORT = `${baseUrl}/format/`;

export const GET_ALL_TABLE_DATA_BY_ID = `${baseUrl}/format/`;

export const UPDATE_VALIDATION_PROTOCOL_REPORT_QUESTIONS = `${baseUrl}/format/specific/`;

export const DOWNLOAD_PV = `${baseUrl}/format/download`;

export const DOWNLOAD_MVP = `${baseUrl}/pro-format/download-mvp`;

export const MAKE_FORMAT_BY_PROCESS = `${baseUrl}/format/make-formats`;

export const GET_BY_YEAR = `${baseUrl}/pro-format/${commonRoutesConstants.YEAR_LIST}`;
