import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

const TemperatureHumidityTable = Loadable(
  lazy(() => import("../TemperatureHumidityTable"))
);
const AppronCleaning = Loadable(lazy(() => import("../AppronCleaning")));
const FumigationCycle = Loadable(lazy(() => import("../FumigationCycle")));
const EsdControlCycle = Loadable(lazy(() => import("../EsdControlCycle")));
const PestControlCycle = Loadable(lazy(() => import("../PestControlCycle")));
const PestControlRecord = Loadable(lazy(() => import("../PestControlRecord")));
const PersonalHygeneRecord = Loadable(
  lazy(() => import("../PersonalHygeneRecord"))
);
const WorkEnvironment = Loadable(lazy(() => import("../WorkEnvironment")));
const FumigationTable = Loadable(lazy(() => import("../FumigationTable")));
const AppronTable = Loadable(lazy(() => import("../AppronTable")));
const HygieneTable = Loadable(lazy(() => import("../HygieneTable")));
const TemperatureTable = Loadable(lazy(() => import("../TemperatureTable")));
const CleanDoneFor = Loadable(lazy(() => import("../CleanDoneFor")));
const CleaningTable = Loadable(lazy(() => import("../CleaningTable")));
const StatusCheck = Loadable(lazy(() => import("../StatusCheck")));
const CleaningRegister = Loadable(lazy(() => import("../CleaningRegister")));
const EsdControlRecord = Loadable(lazy(() => import("../EsdControlRecord")));
const WorkEnvironmentTables = Loadable(
  lazy(() => import("../WorkEnvironmentTables"))
);
const FumigationDoneFor = Loadable(lazy(() => import("../FumigationDoneFor")));
const VersionList = Loadable(lazy(() => import("../VersionList")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });
const WERoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.WORK_ENVIRONMENT, null, [
      createRoute("version-list/:id", <VersionList />),
      createRoute("esd-control-record", <EsdControlRecord />),
      createRoute("pest-control-record", <PestControlRecord />),
      createRoute("cleaning-register/:documentName", <CleaningRegister />),
      createRoute(
        "temperature-table/:documentName",
        <TemperatureHumidityTable />
      ),
      createRoute("Appron-cleaning/:documentName", <AppronCleaning />),
      createRoute("fumigation-cycle/:documentName", <FumigationCycle />),
      createRoute("esd-control/:documentName", <EsdControlCycle />),
      createRoute("pest-control/:documentName", <PestControlCycle />),
      createRoute(
        "personnel-hygiene-record/:documentName",
        <PersonalHygeneRecord />
      ),
      createRoute("environment", <WorkEnvironment />),
      createRoute("fumigation-table", <FumigationTable />),
      createRoute("appron-table", <AppronTable />),
      createRoute("hygine-table", <HygieneTable />),
      createRoute("temperature-data-table", <TemperatureTable />),
      createRoute("clean-done-for", <CleanDoneFor />),
      createRoute("cleaning-table", <CleaningTable />),
      createRoute("status-check", <StatusCheck />),
      createRoute("we-tables/:id", <WorkEnvironmentTables />),
      createRoute("fumigation-done-for", <FumigationDoneFor />),
    ]),
  ]
);

export default WERoutes;
