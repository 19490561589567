import { Button, Grid, TextField } from "@mui/material";
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import {
  extractUserDetails,
  extractUserIdAndName,
  filterUserById,
  formatDateWithYear,
  getDocumentLifeCycleForMasterDoc,
  getFormatDataById,
  removeItem,
  sendNotification,
  showToast,
  validateColumns,
} from "services/utils";
import * as url from "./utils/urlHelper";
import withRouter from "components/withRouter";
import CustomNotification from "components/CustomNotification";
import DynamicFormatStructure from "components/reusables/DynamicFormatStructure";

const CustomerServivingForm = (props) => {
  const { params, navigate } = props?.router,
    {
      ApiServices,
      userData,
      Constants,
      MasterDocumentTypeConstants,
      FormatConstants,
      routeConstants,
    } = props;
  const { id } = params;
  const [data, setData] = useState({});
  const [columns, setColumns] = useState([]);
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const year = formatDateWithYear(new Date());
  const [customerService, setCustomerService] = useState({
    customerName: "",
    customerAddress: "",
    dateAndTimeEvent: "",
    deviceDetails: "",
    descriptionOfProblem: "",
    servicingAt: "",
    notification: false,
    makeDecision: false,
  });
  const [errors, setErrors] = useState();

  const handleSubmitOfCustomerService = async (selectedUser) => {
    try {
      const validationErrors = validateColumns(columns);

      const masterDocData = await getDocumentLifeCycleForMasterDoc(
        FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_FORM,
        MasterDocumentTypeConstants.FORMAT
      );

      if (Object.keys(validationErrors)?.length > 0) {
        setErrors(validationErrors);
        return;
      }
      if (Object.keys(validationErrors)?.length > 0) return;

      if (!selectedUser) {
        setIsOpenNotification(true);
        return;
      }
      let reqObj = {
        masterDocId: masterDocData?._id,
        orgId: userData?.orgId,
        documentName: "Customer Servicing Details",
        prefixType: "FormatPrefixes",
        document: {
          columns: columns,
          isReportFiled: false,
          makeDecision: false,
          // notificationUser: userDatas,
          ...(selectedUser && {
            selectedUser: extractUserDetails(selectedUser),
          }),
        },
      };

      let uri = url.POST_CUSTOMER_SERVICING;
      const response = await ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );

      if (response?.meta?.success) {
        let notificationObj = {
          dataArray: [
            {
              recipients: filterUserById(selectedUser),
              msg: "Servicing Details has been received. Please make a decision.",
              location: `${routeConstants.CUSTOMER_SERVICE}/service-record-history/${year}`,
              status: "Servicing record has been received.",
            },
          ],
        };
        await sendNotification(notificationObj);
      }
      setTimeout(() => {
        navigate("/");
      }, 1000);

      showToast(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomerService = async (id) => {
    try {
      const uri = url.GET_BY_ID_CUSTOMER + id;
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        let obj = { ...result?.data?.document, notification: true };
        setCustomerService(obj);
        removeItem(Constants.TEMPID);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const getFormatData = async () => {
    let result = await getFormatDataById(
      FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_DETAILS
    );
    setData(result ?? null);
    if (result?.structuredData?.length === 1) {
      let columnData = result?.structuredData?.[0];
      setColumns(columnData?.columns);
    }
  };
  useEffect(() => {
    getFormatData();
    if (id) {
      getCustomerService(id);
    }
  }, []);

  return (
    <MainCard title="Customer Servicing Form">
      {isOpenNotification ? (
        <CustomNotification
          open={isOpenNotification}
          setOpen={setIsOpenNotification}
          isMultipleUser={false}
          handleSubmit={handleSubmitOfCustomerService}
        />
      ) : null}
      <Grid container spacing={2}>
        {data ? (
          <DynamicFormatStructure
            data={data?.structuredData}
            columns={columns}
            setColumns={setColumns}
            setErrors={setErrors}
            errors={errors}
          />
        ) : null}
        {columns?.length > 0 ? (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => handleSubmitOfCustomerService()}
            >
              Submit
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </MainCard>
  );
};

export default withRouter(CustomerServivingForm);
