import { routeConstants } from "services/keyConstants";

const baseUrl = `/${routeConstants.MAM_OF_EQUIPMENT}`;

// MAM of Equipment
export const POST_MONITORING_MEASUREMENT_PRODUCT = `${baseUrl}/`;
export const POST_MONITORING_MEASUREMENT_EQUIPMENT = `${baseUrl}/`;

export const GET_BY_ID = `${baseUrl}/`;
export const GET_INTERNAL_CALIBRATION_DATA = `${baseUrl}/`;
export const UPDATE_SPECIFIC = `${baseUrl}/update-specific`;
export const UPDATE_SPECIFIC_REPORT = `${baseUrl}/update-specific-report`;
export const UPDATE_MASTER_LIST_DATA = `${baseUrl}/update-master-list/`;
export const GET_DYNAMIC_MONITORING_MEASUREMENT_EQUIPMENT = `${baseUrl}/get-dynamic`;
export const GET_BY_YEAR = `${baseUrl}/year-list`;
export const DOWNLOAD = `${baseUrl}/download`;
export const GET_BY_MASTERDOCID = `${baseUrl}/get-by-masterdocid`;
export const GET_EQUIPMENT_OR_INSTRUMENT_DATA = `${baseUrl}/get-by-filter`;
