import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Stack,
  Typography,
} from "@mui/material";
import SkeletonTable from "./SkeletonTable";

const Table = (props) => {
  const {
    columns,
    data,
    noDataFound = "No data found",
    isLoading = false,
  } = props;
  return isLoading ? (
    <SkeletonTable />
  ) : (
    <TableContainer component={Paper}>
      <MuiTable>
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCell key={index}>{column?.header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 ? (
            data?.map((item, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns?.map((column, colIndex) => (
                  <TableCell key={colIndex}>
                    {/* Display main content */}
                    {column?.accessor ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: column?.accessor(item,rowIndex),
                        }}
                      />
                    ) : (
                      item[column?.field]
                    )}

                    {/* Conditionally render actions if defined */}
                    {column?.actions && (
                      <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        {" "}
                        {/* Adds spacing between icons */}
                        {column?.actions?.map((action, actionIndex) => (
                          <Tooltip
                            key={actionIndex}
                            title={action?.tooltip || ""}
                          >
                            <IconButton
                              className={action?.className}
                              color={action?.color}
                              onClick={() => action?.onClick?.(item)}
                            >
                              {action?.icon}
                            </IconButton>
                          </Tooltip>
                        ))}
                      </Stack>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns?.length} align="center">
                <Typography variant="body1">{noDataFound}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
