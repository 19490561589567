import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Select,
  FormControl,
  Button,
  Stack,
  IconButton,
  DialogTitle,
  DialogContent,
  InputLabel,
  FormHelperText,
  Autocomplete,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ESign from "components/ESign";
import MainCard from "components/MainCard";
import CustomDialog from "components/reusables/CustomDialog";
import DynamicTableForm from "components/reusables/DynamicTableForm";
import withRouter from "components/withRouter";
import dayjs from "dayjs";
import React, { useState } from "react";
import {
  getISODate,
  getUserData,
  isNotNullOrUndefined,
  toCamelCase,
} from "services/utils";

const ReportDynamicTable = (props) => {
  const {
    tableColumns,
    rows,
    setRows,
    theme,
    handleEsign,
    setHandleEsign,
    setTableColumns,
    userData,
    handleSubmit,
    errors,
    setErrors,
    documentData,
    formColumns,
    setFormColumns,
    formatData,
    batchReleaseChecklist,
    setBatchReleaseChecklist,
    setFinalQuantity,
    finalQuantity,
    setOpenFinishedProductRegister,
    openFinishedProductRegister,
  } = props;

  const [currectColIndex, setCurrentColIndex] = useState("");
  const [editCallName, setEditCallName] = useState("");
  const [currentData, setCurrentData] = useState({});
  const [newColumns, setNewColumns] = useState({});
  const [formErrors, setFormErrors] = useState([]);
  // Function to handle changes in the table inputs
  const handleChange = (rowIndex, paramIndex, columnKey, newValue) => {
    // Create a new copy of the rows
    const updatedRows = rows.map((row, rIndex) => {
      if (rIndex === rowIndex) {
        // Create a new copy of the current row
        const updatedRow = { ...row };

        if (paramIndex !== null && paramIndex !== undefined) {
          // Update the specific parameter
          if (columnKey === "isSubmittedObservation") {
            updatedRow.parametersToBeInspected =
              updatedRow.parametersToBeInspected.map((param, pIndex) => {
                let sampleS = "";
                if (param?.type == "Percentage(%)") {
                  let quantity =
                    updatedRow?.quantityAccepted ??
                    documentData?.productData?.quantity;
                  sampleS = Math.ceil(
                    (param?.observationExtraValue / 100) * quantity
                  );
                } else if (param?.type == "Random") {
                  sampleS = param?.observationExtraValue;
                }
                if (pIndex === paramIndex) {
                  return {
                    ...param,
                    [columnKey]: newValue,
                    observations: Array(parseInt(sampleS))?.fill("") ?? [],
                    sampleSize: parseInt(sampleS),
                  }; // Update the specific parameter
                }
                return param; // Return the unchanged parameter
              });
          } else {
            updatedRow.parametersToBeInspected =
              updatedRow.parametersToBeInspected.map((param, pIndex) => {
                if (pIndex === paramIndex) {
                  return { ...param, [columnKey]: newValue }; // Update the specific parameter
                }
                return param; // Return the unchanged parameter
              });
            if (columnKey == "observations") {
              updatedRow.isError = undefined;
            }
          }
        } else {
          // Update the row property directly
          updatedRow[columnKey] = newValue;
        }

        return updatedRow; // Return the updated row
      }
      return row; // Return the unchanged row
    });

    if (columnKey === "quantityValue") {
      const totalRejectedQuantity = getTotalQuantityValue(updatedRows);
      const remainingQuantity =
        (documentData?.productData?.quantity || 0) - totalRejectedQuantity;
      // Update the final quantity state
      setFinalQuantity(remainingQuantity);
    }

    setRows(updatedRows);

    const updatedErrors = { ...errors };

    // Clear error if the updated value satisfies the validation
    if (columnKey && newValue) {
      delete updatedErrors?.[columnKey];
    }
    if (newValue) {
      delete updatedErrors[`${columnKey}-${rowIndex}`];
    }

    // Update errors state
    setErrors(updatedErrors);
  };

  const getTotalQuantityValue = (data) => {
    return data?.reduce((total, item) => {
      // Directly access the quantityValue from the item
      const quantityValue = Number(item?.quantityValue) || 0;
      return total + quantityValue;
    }, 0); // Starting total is 0
  };

  const handleSave = async (success) => {
    if (success) {
      let getUser = getUserData(userData);

      getUser = {
        ...getUser,
        userDesignation: `${getUser.name} - ${getUser.designation}`,
        signedOn: new Date().toISOString(),
      };
      const updatedRows = {
        ...currentData?.row,
        inspectionDoneBy: getUser,
      };
      const newRows = [...rows];
      newRows[currentData?.index] = updatedRows;
      setRows(newRows);

      // Submit the updated rows
      await handleSubmit(updatedRows, currentData?.index, tableColumns);
    }
  };
  const saveColumnName = (index) => {
    if (editCallName?.trim() !== "") {
      const updatedColumns = [...tableColumns];
      const oldKey = updatedColumns[index].value;
      const newKey = toCamelCase(editCallName);
      updatedColumns[index].name = editCallName;
      // updatedColumns[index].value = newKey;

      // Update rows with the new column key
      // const updatedRows = rows.map((row) => {
      //     if (row.hasOwnProperty(oldKey)) {
      //         row[newKey] = row[oldKey];
      //         delete row[oldKey];
      //     }
      //     if (row.parametersToBeInspected) {
      //         row.parametersToBeInspected = row.parametersToBeInspected.map((param) => {
      //             if (param.hasOwnProperty(oldKey)) {
      //                 param[newKey] = param[oldKey];
      //                 delete param[oldKey];
      //             }
      //             return param;
      //         });
      //     }
      //     return row;
      // });
      // setRows(updatedRows);
      setTableColumns(updatedColumns);
      setCurrentColIndex("");
      setEditCallName("");
    } else {
      alert("Column name cannot be empty.");
    }
  };

  const deleteColumn = (index) => {
    const updatedColumns = [...tableColumns];
    const columnKey = updatedColumns[index]?.value;
    updatedColumns.splice(index, 1); // Remove column from tableColumns
    setTableColumns(updatedColumns);

    // Remove the corresponding key from rows and parameters
    const updatedRows = rows.map((row) => {
      // Remove top-level row key
      if (row.hasOwnProperty(columnKey)) {
        delete row[columnKey];
      }

      // Remove nested `parametersToBeInspected` key
      if (row?.parametersToBeInspected) {
        row.parametersToBeInspected = row?.parametersToBeInspected?.map(
          (param) => {
            if (param.hasOwnProperty(columnKey)) {
              delete param[columnKey];
            }
            return param;
          }
        );
      }

      return row;
    });
    setRows(updatedRows);
  };

  const handleAddRow = (rowIndex) => {
    setRows((prevRows) => {
      const newRow = {
        process: "",
        parametersToBeInspected: [{}],
      };
      return [
        ...prevRows.slice(0, rowIndex + 1), // Rows before the current index
        newRow, // Updated row with new parameter
        ...prevRows.slice(rowIndex + 1), // Rows after the current index
      ];
    });
  };

  const handleDeleteRow = (rowIndex) => {
    setRows((prevRows) => prevRows.filter((_, index) => index !== rowIndex));
  };
  const addColumn = () => {
    const { columnName, type } = newColumns;

    if (columnName?.trim() === "") {
      alert("Column name cannot be empty.");
      return;
    }

    if (type === "") {
      alert("Please select type.");
      return;
    }

    const newColumn = {
      name: columnName,
      value: toCamelCase(columnName),
      source: type === "In Parameters" ? "parameter" : "row", // Determine the source based on the selection
      type: "text", // You can modify this to set the type based on your requirements
    };

    // Update table columns
    setTableColumns((prevColumns) => [...prevColumns, newColumn]);

    // Update rows to include the new column
    const updatedRows = rows?.map((row) => {
      if (type === "In Parameters") {
        // If adding to parameters, add a new parameter object
        const updatedParameters = row?.parametersToBeInspected?.map(
          (param) => ({ ...param, [newColumn.value]: "" })
        );
        return {
          ...row,
          parametersToBeInspected: updatedParameters,
        };
      } else {
        // If adding as a top-level column, initialize with an empty string or default value
        return {
          ...row,
          [newColumn.value]: "", // Initialize with an empty string or default value
        };
      }
    });

    setRows(updatedRows);
    setNewColumns({}); // Reset the newColumns state
  };

  const validateRowByIndex = (row, index) => {
    const errors = {};
    tableColumns?.forEach((column) => {
      if (column?.source === "row") {
        // Retrieve the value for the current column
        const value = row[column.value];

        // Validate required fields
        if (
          column?.validation?.required &&
          column?.value !== "quantityValue" &&
          !value
        ) {
          errors[column?.value] =
            column.validation.msg || "This field is required.";
        }
        if (
          column?.value === "quantityValue" &&
          column.validation?.required &&
          row["acceptedRejectedQuantity"] === "Rejected" &&
          !value
        ) {
          errors[column?.value] =
            column.validation.msg || "This field is required.";
        }
      }
    });

    // Validate the specific parameter row based on the given index
    if (
      row?.parametersToBeInspected &&
      Array.isArray(row.parametersToBeInspected)
    ) {
      // Loop through parametersToBeInspected and validate each one
      row.parametersToBeInspected.map((parameter, index) => {
        // Loop through columns related to parameters and validate them dynamically
        tableColumns?.forEach((column) => {
          if (column?.source === "parameter") {
            const value = parameter[column.value];

            // Check if validation is required for the parameter field
            if (column.validation?.required && !value) {
              errors[`${column.value}-${index}`] = column.validation.msg;
            }
          }
        });

        // Dynamically validate other fields based on column configuration
        tableColumns?.forEach((column) => {
          if (column?.source === "parameter" && column.validation?.required) {
            const value = parameter[column.value];
            if (!value) {
              errors[`${column?.value}-${index}`] = column?.validation?.msg;
            }
          }
        });
      });
    }

    // Set the errors in the state
    return errors;
  };
  console.log(errors, "errors");
  const handleEsignButton = (row, index) => {
    const selectedRow = rows[index];
    const validationErrors = validateRowByIndex(selectedRow, index);
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...validationErrors, index: index });
      return;
    } else {
      setHandleEsign(true);
      setCurrentData({ row, index: index });
    }
  };
  return (
    <MainCard>
      {handleEsign && (
        <ESign
          open={handleEsign}
          setOpen={setHandleEsign}
          handleSubmit={handleSave}
        />
      )}
      {formatData ? (
        <DynamicTableForm
          data={formatData}
          columns={formColumns}
          setColumns={setFormColumns}
          setErrors={setFormErrors}
          errors={formErrors}
          isViewMode={true}
        />
      ) : null}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableColumns?.map((column, index) => (
                <TableCell sx={{ minWidth: 160 }} key={index}>
                  {/* {currectColIndex === index ? (
                                        <TextField
                                            value={editCallName}
                                            onChange={(e) => setEditCallName(e.target.value)}
                                            onBlur={() => saveColumnName(index)}
                                        />
                                    ) : ( */}
                  <Stack direction="row" alignItems={"center"} spacing={1}>
                    {column?.name}
                    {/* <IconButton
                                                onClick={() => {
                                                    setCurrentColIndex(index);
                                                    setEditCallName(column?.name);
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <IconButton color="error" onClick={() => deleteColumn(index)}>
                                                <Delete />
                                            </IconButton> */}
                  </Stack>
                  {/* )} */}
                </TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, rowIndex) =>
              row?.parametersToBeInspected?.map((param, paramIndex) => (
                <TableRow key={`${rowIndex}-${paramIndex}`}>
                  {tableColumns?.map((col, colIndex) => {
                    if (col?.source === "row") {
                      if (col?.type === "index" && paramIndex === 0) {
                        return (
                          <TableCell
                            key={colIndex}
                            rowSpan={row?.parametersToBeInspected?.length}
                          >
                            {rowIndex + 1}
                          </TableCell>
                        );
                      }

                      if (col?.type === "currentUser" && paramIndex === 0) {
                        return (
                          <TableCell
                            rowSpan={row?.parametersToBeInspected?.length}
                            key={colIndex}
                          >
                            {row?.[col?.value] ? (
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "columns",
                                }}
                                gap={1}
                              >
                                {row?.[col?.value]?.userDesignation}
                              </Stack>
                            ) : (
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => handleEsignButton(row, rowIndex)}
                              >
                                E-Sign
                              </Button>
                            )}
                          </TableCell>
                        );
                      }

                      if (col?.type === "text" && paramIndex === 0) {
                        return (
                          <TableCell
                            rowSpan={row?.parametersToBeInspected?.length}
                            key={colIndex}
                          >
                            <TextField
                              value={row?.[col?.value]}
                              onChange={(e) =>
                                handleChange(
                                  rowIndex,
                                  null,
                                  col?.value,
                                  e.target.value
                                )
                              }
                              error={
                                errors?.index === rowIndex &&
                                !!errors[`${col?.value}`]
                              } // Show error if exists
                              helperText={
                                (errors?.index === rowIndex &&
                                  errors[`${col?.value}`]) ||
                                ""
                              }
                            />
                          </TableCell>
                        );
                      }

                      if (col?.type === "dropdown" && paramIndex === 0) {
                        if (col?.value === "process") {
                          return (
                            <TableCell
                              rowSpan={row?.parametersToBeInspected?.length}
                              key={colIndex}
                            >
                              <TextField
                                value={`${row?.processName || ""}${
                                  row?.type === "Rework" ? " - Rework" : ""
                                }`}
                                onChange={(e) =>
                                  handleChange(
                                    rowIndex,
                                    null,
                                    col?.value,
                                    e.target.value
                                  )
                                }
                                error={
                                  errors?.index === rowIndex &&
                                  !!errors[`${col?.value}`]
                                } // Show error if exists
                                helperText={
                                  (errors?.index === rowIndex &&
                                    errors[`${col?.value}`]) ||
                                  ""
                                }
                              />
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              rowSpan={row?.parametersToBeInspected?.length}
                              key={colIndex}
                            >
                              <FormControl
                                error={
                                  errors?.index === rowIndex &&
                                  !!errors[`${col?.value}`]
                                }
                                fullWidth
                              >
                                <Select
                                  value={row?.[col?.value] || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      rowIndex,
                                      null,
                                      col?.value,
                                      e.target.value
                                    )
                                  }
                                >
                                  <MenuItem disabled>Select</MenuItem>
                                  {col?.options?.map((option, optIndex) => (
                                    <MenuItem
                                      key={optIndex}
                                      value={
                                        option?.[col?.renderOptions] ?? option
                                      }
                                    >
                                      {option?.[col?.renderName] ?? option}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors?.index === rowIndex &&
                                  !!errors[`${col?.value}`] && (
                                    <FormHelperText error>
                                      {errors[`${col?.value}`]}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </TableCell>
                          );
                        }
                      }

                      if (col?.type === "date" && paramIndex === 0) {
                        return (
                          <TableCell
                            rowSpan={row?.parametersToBeInspected?.length}
                            key={colIndex}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label={col?.name}
                                value={
                                  row?.[col?.value]
                                    ? dayjs(row?.[col?.value])
                                    : null
                                }
                                format="DD/MM/YYYY"
                                onChange={(newValue) =>
                                  handleChange(
                                    rowIndex,
                                    null,
                                    col?.value,
                                    newValue
                                  )
                                }
                                componentsProps={{
                                  textField: {
                                    error:
                                      errors?.index === rowIndex &&
                                      !!errors[`${col?.value}`],
                                  },
                                }}
                              />
                            </LocalizationProvider>
                            {errors?.index === rowIndex &&
                              !!errors[`${col?.value}`] && (
                                <FormHelperText error>
                                  {errors[`${col?.value}`]}
                                </FormHelperText>
                              )}
                          </TableCell>
                        );
                      }

                      if (col?.type === "dynamicDropdown" && paramIndex === 0) {
                        return (
                          <FormControl
                            fullWidth
                            key={colIndex}
                            error={
                              errors?.index === rowIndex &&
                              !!errors[`${col?.value}-${paramIndex}`]
                            }
                          >
                            <TableCell
                              rowSpan={row?.parametersToBeInspected?.length}
                              key={colIndex}
                            >
                              <Autocomplete
                                multiple // Enable multiple selections
                                freeSolo // Allow values not in the options
                                value={
                                  Array.isArray(row?.[col?.value])
                                    ? row?.[col?.value]
                                    : []
                                }
                                onChange={(e, newValue) => {
                                  handleChange(
                                    rowIndex,
                                    null,
                                    col?.value,
                                    { target: { value: newValue } } // Pass the updated array
                                  );
                                }}
                                options={
                                  Array.isArray(col?.options) ? col.options : []
                                } // Provide options
                                renderInput={(params) => (
                                  <TextField {...params} label={col?.name} />
                                )}
                              />
                            </TableCell>
                          </FormControl>
                        );
                      }

                      if (col?.type === "number" && paramIndex === 0) {
                        return (
                          <TableCell
                            rowSpan={row?.parametersToBeInspected?.length}
                            key={colIndex}
                          >
                            <TextField
                              type="number"
                              value={
                                row?.[col?.value] !== undefined
                                  ? row?.[col?.value]
                                  : ""
                              } // Ensure value is a string
                              onChange={(e) =>
                                handleChange(
                                  rowIndex,
                                  null,
                                  col?.value,
                                  parseInt(e.target.value)
                                )
                              }
                              error={
                                errors?.index === rowIndex &&
                                !!errors[`${col?.value}`]
                              } // Show error if exists
                              helperText={
                                (errors?.index === rowIndex &&
                                  errors[`${col?.value}`]) ||
                                ""
                              }
                              disabled={
                                row?.acceptedRejectedQuantity === "Accepted" &&
                                col?.value === "quantityValue"
                              }
                            />
                          </TableCell>
                        );
                      }
                    }

                    if (col?.source === "parameter") {
                      if (col?.type === "text") {
                        return (
                          <TableCell key={colIndex}>
                            <TextField
                              label={col?.name}
                              value={param?.[col?.value] || 0}
                              onChange={(e) =>
                                handleChange(
                                  rowIndex,
                                  paramIndex,
                                  col?.value,
                                  e.target.value
                                )
                              }
                              error={
                                errors?.index === rowIndex &&
                                !!errors[`${col?.value}-${paramIndex}`]
                              } // Show error if exists
                              helperText={
                                (errors?.index === rowIndex &&
                                  errors[`${col?.value}-${paramIndex}`]) ||
                                ""
                              }
                            />
                          </TableCell>
                        );
                      }

                      if (col?.type === "number") {
                        return (
                          <TableCell key={colIndex}>
                            <TextField
                              type="number"
                              value={
                                param?.[col?.value] !== undefined
                                  ? param?.[col?.value]
                                  : ""
                              } // Ensure value is a string
                              onChange={(e) =>
                                handleChange(
                                  rowIndex,
                                  paramIndex,
                                  col?.value,
                                  parseInt(e.target.value)
                                )
                              }
                              error={
                                errors?.index === rowIndex &&
                                !!errors[`${col?.value}-${paramIndex}`]
                              } // Show error if exists
                              helperText={
                                (errors?.index === rowIndex &&
                                  errors[`${col?.value}-${paramIndex}`]) ||
                                ""
                              }
                            />
                          </TableCell>
                        );
                      }

                      if (col?.type === "dropdown") {
                        return (
                          <TableCell key={colIndex}>
                            <FormControl
                              error={
                                errors?.index === rowIndex &&
                                !!errors[`${col?.value}-${paramIndex}`]
                              }
                              fullWidth
                            >
                              <Select
                                value={param?.[col?.value] || ""}
                                onChange={(e) =>
                                  handleChange(
                                    rowIndex,
                                    paramIndex,
                                    col?.value,
                                    e.target.value
                                  )
                                }
                              >
                                <MenuItem disabled>Select</MenuItem>
                                {col?.options?.map((option, optIndex) => (
                                  <MenuItem
                                    key={optIndex}
                                    value={
                                      option?.[col?.renderOptions] ?? option
                                    }
                                  >
                                    {option?.[col?.renderName] ?? option}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors?.index === rowIndex &&
                                !!errors[`${col?.value}-${paramIndex}`] && (
                                  <FormHelperText error>
                                    {errors[`${col?.value}-${paramIndex}`]}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </TableCell>
                        );
                      }

                      if (col?.type === "dynamicDropdown") {
                        return (
                          <FormControl
                            fullWidth
                            key={colIndex}
                            error={
                              errors?.index === rowIndex &&
                              !!errors[`${col?.value}`]
                            }
                          >
                            <TableCell key={colIndex}>
                              <Autocomplete
                                multiple // Enable multiple selections
                                freeSolo // Allow values not in the options
                                value={
                                  Array.isArray(param?.[col?.value])
                                    ? param?.[col?.value]
                                    : []
                                }
                                onChange={(e, newValue) => {
                                  handleChange(
                                    rowIndex,
                                    paramIndex,
                                    col?.value,
                                    { target: { value: newValue } } // Pass the updated array
                                  );
                                }}
                                options={
                                  Array.isArray(col?.options) ? col.options : []
                                } // Provide options
                                renderInput={(params) => (
                                  <TextField {...params} label={col?.name} />
                                )}
                              />
                            </TableCell>
                          </FormControl>
                        );
                      }

                      if (col?.type === "date") {
                        return (
                          <TableCell key={colIndex}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label={col?.name}
                                value={param?.[col?.value] || ""}
                                onChange={(newValue) =>
                                  handleChange(
                                    rowIndex,
                                    paramIndex,
                                    col?.value,
                                    newValue
                                  )
                                }
                              />
                            </LocalizationProvider>
                            {errors?.index === rowIndex &&
                              !!errors[`${col?.value}-${paramIndex}`] && (
                                <FormHelperText error>
                                  {!!errors[`${col?.value}-${paramIndex}`]}
                                </FormHelperText>
                              )}
                          </TableCell>
                        );
                      }

                      if (col?.type === "array-string") {
                        return (
                          <TableCell
                            ref={(el) => {
                              if (el) {
                                const columnClass = `col-name-sync`;
                                const cells = document.querySelectorAll(
                                  `.${columnClass}`
                                );
                                el.addEventListener("scroll", (event) => {
                                  const scrollLeft = event.target.scrollLeft;
                                  cells.forEach((cell) => {
                                    if (cell !== event.target) {
                                      cell.scrollLeft = scrollLeft;
                                    }
                                  });
                                });
                              }
                            }}
                            className={`col-name-sync`}
                            sx={{
                              display:
                                param?.observations?.length > 0
                                  ? "flex"
                                  : "auto",
                              overflowX:
                                param?.observations?.length > 0
                                  ? "scroll"
                                  : "auto",
                              maxWidth: "500px",
                              "&::-webkit-scrollbar": {
                                width: "2px",
                                height: "4px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: "10px",
                              },
                            }}
                            key={colIndex}
                          >
                            {param?.[col?.value]?.length > 0 ? (
                              param?.[col?.value]?.map(
                                (observation, observationIndex) => {
                                  return (
                                    <TextField
                                      key={observationIndex}
                                      value={observation}
                                      sx={{ minWidth: "130px", marginLeft: 1 }}
                                      label={`Observations ${
                                        observationIndex + 1
                                      }`}
                                      onChange={(e) => {
                                        const newValue = e?.target?.value;

                                        if (
                                          !isNaN(newValue) &&
                                          param?.methodOfInspection?.includes(
                                            "Visual Inspection"
                                          )
                                        ) {
                                          handleChange(
                                            rowIndex,
                                            null,
                                            "isError",
                                            true
                                          ); // Call handleChange to update the state
                                        } else {
                                          // Update the observation value in the state
                                          const updatedObservations = [
                                            ...param?.observations,
                                          ];
                                          updatedObservations[
                                            observationIndex
                                          ] = newValue; // Update the specific observation
                                          handleChange(
                                            rowIndex,
                                            paramIndex,
                                            "observations",
                                            updatedObservations
                                          ); // Call handleChange to update the state
                                          // handleChange(rowIndex, null, "isError", undefined);
                                        }
                                      }}
                                      error={row?.isError}
                                      helperText={
                                        row?.isError
                                          ? "Numeric values are not allowed for Visual Inspection."
                                          : ""
                                      }
                                    />
                                  );
                                }
                              )
                            ) : ["Percentage(%)", "Random"]?.includes(
                                param?.type
                              ) && param?.isSubmittedObservation === false ? (
                              <FormControl fullWidth>
                                <TextField
                                  InputProps={{
                                    endAdornment: (
                                      <>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          style={{ marginLeft: "5px" }}
                                          onClick={() => {
                                            handleChange(
                                              rowIndex,
                                              paramIndex,
                                              "isSubmittedObservation",
                                              true
                                            );
                                          }}
                                        >
                                          Submit
                                        </Button>
                                      </>
                                    ),
                                  }}
                                  value={param?.observationExtraValue}
                                  onChange={(e) =>
                                    handleChange(
                                      rowIndex,
                                      paramIndex,
                                      "observationExtraValue",
                                      e?.target?.value
                                    )
                                  }
                                  type="number"
                                />
                              </FormControl>
                            ) : (
                              param?.observations?.map(
                                (observation, observationIndex) => {
                                  return (
                                    <TextField
                                      value={observation}
                                      key={observationIndex}
                                      sx={{ minWidth: "130px", marginLeft: 1 }}
                                      label={`Observations ${
                                        observationIndex + 1
                                      }`}
                                      onChange={(e) => {
                                        const newValue = e?.target?.value;
                                        if (
                                          !isNaN(newValue) &&
                                          param?.methodOfInspection?.includes(
                                            "Visual Inspection"
                                          )
                                        ) {
                                          handleChange(
                                            rowIndex,
                                            null,
                                            "isError",
                                            true
                                          ); // Call handleChange to update the state
                                        } else {
                                          // Update the observation value in the state
                                          const updatedObservations = [
                                            ...param?.observations,
                                          ];
                                          updatedObservations[
                                            observationIndex
                                          ] = newValue; // Update the specific observation
                                          handleChange(
                                            rowIndex,
                                            paramIndex,
                                            "observations",
                                            updatedObservations
                                          ); // Call handleChange to update the state
                                          // handleChange(rowIndex, null, "isError", undefined);
                                        }
                                      }}
                                      error={row?.isError}
                                      helperText={
                                        row?.isError
                                          ? "Numeric values are not allowed for Visual Inspection."
                                          : ""
                                      }
                                    />
                                  );
                                }
                              )
                            )}
                          </TableCell>
                        );
                      }
                    }

                    return null;
                  })}
                  {paramIndex === 0 && (
                    <TableCell rowSpan={row?.parametersToBeInspected?.length}>
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          color="success"
                          disabled
                          onClick={() => handleAddRow(rowIndex)}
                        >
                          <Add />
                        </IconButton>
                        <IconButton
                          color="error"
                          disabled
                          onClick={() => handleDeleteRow(rowIndex)}
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {/* <Stack direction="row" margin={2} spacing={2}>
                    <FormControl fullWidth>
                        <TextField label="Column Name" value={newColumns?.columnName} onChange={(e) => setNewColumns((pre) => ({ ...pre, columnName: e?.target?.value }))} name='columnName' />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Where you add column?</InputLabel>
                        <Select
                            value={newColumns?.type}
                            onChange={(e) => setNewColumns((pre) => ({ ...pre, type: e?.target?.value }))}
                        >
                            {
                                ["In Parameters", "Outside"]?.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <Button sx={{ margin: 2 }} variant='contained' onClick={() => addColumn()}>Add Column</Button>
                </Stack> */}
      </TableContainer>

      <Box>
        <Typography variant="h4" sx={{ textAlign: "center" }}></Typography>
        {batchReleaseChecklist ? (
          <>
            <DynamicTableForm
              data={formatData}
              columns={batchReleaseChecklist}
              setColumns={setBatchReleaseChecklist}
              setErrors={setFormErrors}
              errors={formErrors}
              tableName={"Batch Release Checklist"}
              isStrictDataForRow={true}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Approved By & Final Release done by</TableCell>
                  <TableCell>Final Quality Released For Distribution</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {isNotNullOrUndefined(documentData?.esign) ? (
                      <>
                        <>
                          <Grid item xs={6}>
                            <TextField
                              label="Name"
                              variant="outlined"
                              size="small"
                              value={documentData?.esign?.userData?.name}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            {" "}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                fullWidth
                                sx={{ width: "100%" }}
                                id="outlined-basic"
                                label="Time"
                                format="DD/MM/YYYY"
                                value={
                                  documentData?.esign?.time
                                    ? dayjs(documentData?.esign?.time)
                                    : null
                                }
                                readOnly={true}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      </>
                    ) : batchReleaseChecklist?.every(
                        (item) => item?.inputValue === "Yes"
                      ) ? (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() =>
                          setOpenFinishedProductRegister(
                            !openFinishedProductRegister
                          )
                        }
                      >
                        Esign
                      </Button>
                    ) : null}
                  </TableCell>

                  <TableCell>{finalQuantity}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        ) : null}
      </Box>
    </MainCard>
  );
};

export default withRouter(ReportDynamicTable);
