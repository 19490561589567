import { Button, Stack } from "@mui/material";
import ESign from "components/ESign";
import MainCard from "components/MainCard";
import withRouter from "components/withRouter";
import React, { useEffect, useState } from "react";
import * as url from "../utils/urlHelper";
import { transformEquipmentData } from "../utils/utils";
import {
  buildUrl,
  extractUserDetails,
  filterUserById,
  getDocumentLifeCycleForMasterDoc,
  getFormatDataById,
  getUserData,
  sendNotification,
  showToast,
} from "services/utils";
import {
  FormatConstants,
  MasterDocumentTypeConstants,
  TypeConstants,
} from "services/keyConstants";
import { ADD_DATA } from "pages/finishedProductRegister/utils/urlHelper";
import { getUserDataByDesignation } from "services/organogramServices";
import ReportDynamicTable from "../ReportDynamicTable";

const FinalInspectionForm = (props) => {
  const { ApiServices, DepartmentConstants, userData } = props,
    { params, navigate } = props?.router;
  const [isOpen, setIsOpen] = useState(false);
  const { id } = params;
  const [documentData, setDocumentData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [finalQuantity, setFinalQuantity] = useState(0);
  const [openFinishedProductRegister, setOpenFinishedProductRegister] =
    useState(false);
  const [planColumns, setPlanColumns] = useState([]);
  const [batchReleaseChecklist, setBatchReleaseChecklist] = useState([]);
  const [formColumns, setFormColumns] = useState([]);
  const [formatData, setFormatData] = useState({});
  const [tableColumns, setTableColumns] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getDataById();
    getFormatData();
    getPlanFormatData();
  }, [id]);

  useEffect(() => {
    getInstrumentData();
  }, []);
  console.log("document>>", documentData);
  const getDataById = async () => {
    try {
      setIsLoading(true);
      const uri = url.GET_INSPECTION_DATA_BY_ID + id;
      let result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        let sampleSizeData = result?.meta?.sampleSizeData?.document ?? null;
        const fullData = generateFullData(
          result?.data?.productData?.processData,
          result?.data?.productData?.quantity,
          sampleSizeData
        );
        setData(fullData);
        let remainingQuantity = 0;
        if (result?.data?.productData?.quantity) {
          const totalRejectedQuantity = getTotalQuantityValue(
            result?.data?.productData?.processData
          );
          remainingQuantity =
            (result?.data?.productData?.quantity || 0) - totalRejectedQuantity;
          // Update the final quantity state
        }
        setFinalQuantity(result?.data?.finalQuantity ?? remainingQuantity);

        if (result?.data?.batchRecords) {
          setBatchReleaseChecklist(result?.data?.batchRecords);
        } else {
          const columnsFormatData = await getFormatData();
          setBatchReleaseChecklist(
            columnsFormatData?.structuredData?.[2]?.columns ?? []
          );
        }
        setDocumentData({
          ...result?.data,
          sampleSizeData: sampleSizeData ?? [],
        });
        const productData = result?.data?.productData;
        await setColumnsData(productData, result?.data?.reportNumber);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setColumnsData = async (productData, reportNumber) => {
    const columnsFormatData = await getFormatData();
    const columnsFormData = columnsFormatData?.structuredData?.[0]?.columns;
    let updated = columnsFormData?.map((column) => {
      const matchingProductData = productData[column?.value];
      if (column?.value === "reportNumber") {
        return {
          ...column,
          inputValue: reportNumber,
        };
      }
      return {
        ...column,
        inputValue:
          matchingProductData !== undefined ? matchingProductData : null,
      };
    });
    setFormColumns(updated);
    let columnData = columnsFormatData?.structuredData?.[1];
    setFormatData(columnsFormatData);
    // setBatchReleaseChecklist(columnsFormatData?.structuredData?.[2]?.columns ?? [])
    const equipmentData = await getInstrumentData();
    const updatedColumns = columnData?.columns?.map((column) => {
      if (
        column?.source === "parameter" &&
        column?.value === "equipmentDetails"
      ) {
        return { ...column, options: equipmentData };
      } else {
        return column;
      }
    });
    setTableColumns(updatedColumns);
  };

  const getInstrumentData = async () => {
    try {
      setIsLoading(true);
      const masterDocData = await getDocumentLifeCycleForMasterDoc(
        FormatConstants.MAMOFEQUIPMENT.MASTERLIST_OF_EQUIPMENT,
        MasterDocumentTypeConstants.FORMAT
      );
      const queryParams = {
        masterDocId: masterDocData?._id,
        // year: "2025",
      };
      const uri = buildUrl(url.GET_BY_MASTERDOCID_BY_EQUIPMENT, queryParams);
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        // make array of object for equipment name and id merge
        let data = transformEquipmentData(result?.data[0]?.document);
        return data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFormatData = async () => {
    try {
      let result = await getFormatDataById(
        FormatConstants.MAMOFPRODUCT.FINAL_INSPECTION_REPORT
      );
      if (result?.structuredData?.length === 3) {
        return result;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTotalQuantityValue = (data) => {
    return data?.reduce((total, item) => {
      // Directly access the quantityValue from the item
      const quantityValue = Number(item?.quantityValue) || 0;
      return total + quantityValue;
    }, 0); // Starting total is 0
  };

  const handleSave = async (updatedRecord, index, columns) => {
    const masterDocId = await getDocumentLifeCycleForMasterDoc(
      FormatConstants.NON_CONFORMING_OF_PRODUCT.NON_CONFORMING_OF_PRODUCT,
      MasterDocumentTypeConstants.FORMAT
    );

    if (!masterDocId) {
      return showToast("Please create non conforming procedure");
    }
    const filteredDataObservations =
      updatedRecord?.parametersToBeInspected?.map((param) => {
        return {
          ...param,
          observations: param?.observations?.filter(
            (obs) => obs !== null && obs !== undefined && obs !== ""
          ),
        };
      });

    let reqObj = {
      id: documentData?._id,
      updateData: {
        [`productData.columns`]: columns,
        [`productData.processData.${index}`]: {
          ...updatedRecord,
          parametersToBeInspected: filteredDataObservations,
        },
        [`productData.formColumns`]: formColumns,
      },
      index: index,
      bmrModelId: documentData?.productData?.bmrId,
      parentBmrId: documentData?.productData?.parentBmrId,
      isFinalProcess: true,
      documentName: "Final Inspection Report",
      masterDocIdForNonConfirming: masterDocId?._id,
      prefixType: "FormatPrefixes",
      reportPrefixType: "MAMOfInProcessReportPrefixes",
    };
    const result = await ApiServices.callServicePutWithBodyData(
      url.UPDATE_SPECIFIC_BY_ID,
      reqObj
    );
    showToast(result);

    if (result?.meta?.success) {
      const channel = new BroadcastChannel("bmrChannel");
      // Broadcast the event to all tabs
      channel.postMessage({ type: "bmrUpdate", data: result });
      getDataById();
    }
  };

  const getPlanFormatData = async () => {
    try {
      let result = await getFormatDataById(
        FormatConstants.MAMOFPRODUCT.FINAL_INSPECTION_Plan
      );
      if (result?.structuredData?.length === 1) {
        const newColls = result?.structuredData?.[0]?.columns?.map((col) => {
          if (!col?.source) {
            return {
              ...col,
              source: "parameter",
              type: "text",
            };
          } else {
            return col;
          }
        });
        setPlanColumns(newColls);
        return result;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleESignUpdate = async (success) => {
    if (success) {
      let obj = {
        finalSignBy: {
          time: new Date().toISOString(),
          userData: getUserData(userData),
        },
      };
      setIsOpen(null);

      try {
        const masterDocData = await getDocumentLifeCycleForMasterDoc(
          FormatConstants.FINISHED_REGISTER_PRODUCT.FINISHED_PRODUCT_REGISTER,
          MasterDocumentTypeConstants.FORMAT
        );

        if (!masterDocData) {
          showToast(
            "Please create customer related process and distribution procedure."
          );
          return;
        }
        const reqObj = {
          id: documentData?._id,
          esign: obj?.finalSignBy,
          batchRecords: batchReleaseChecklist,
          bmrModelId: documentData?.productData?.bmrId,
          finalQuantity: finalQuantity,
        };

        // update data
        const uri = url.UPDATE_SPECIFIC_FINAL;

        const result = await ApiServices.callServicePutWithBodyData(
          uri,
          reqObj
        );
        if (result?.meta?.success) {
          getDataById();
          let dispatchDepartmentUsers = await getUserDataByDesignation([
            {
              type: TypeConstants.TYPE_FIND_ALL_USERS_BY_DEPARTMENT,
              department: [DepartmentConstants.DISPATCH_DEPARTMENT],
            },
            {
              type: TypeConstants.TYPE_FIND_HEAD,
              department: [DepartmentConstants.DISPATCH_DEPARTMENT],
            },
          ]);

          const transformed = extractUserDetails(
            dispatchDepartmentUsers[TypeConstants.TYPE_FIND_HEAD]
          );
          let finalRegisterObj = {
            masterDocId: masterDocData?._id,
            orgId: userData?.orgId,
            bmrId: documentData?.productData?.bmrId,
            receivedDate: new Date().toISOString(),
            quantity: finalQuantity,
            receiveBy: filterUserById(transformed),
            extraDetails: {
              receiver: transformed,
              dispatchDepartmentUsers:
                dispatchDepartmentUsers[
                  TypeConstants.TYPE_FIND_ALL_USERS_BY_DEPARTMENT
                ],
              dispatchDepartmentHeadUser:
                dispatchDepartmentUsers[TypeConstants.TYPE_FIND_HEAD],
            },
          };

          const uri = ADD_DATA;
          const tempResult = await ApiServices.callServicePostWithBodyData(
            uri,
            finalRegisterObj
          );

          // update data
          if (tempResult?.meta?.success) {
            let notificationObj = {
              dataArray: [
                {
                  recipients: filterUserById(transformed),
                  msg: "An final inspection has been done and it has been added to finished product register",
                  status: "Stock Updated.",
                  location: `finished-product-register/view-table/${documentData?.productDetails?.productId}/${documentData?.productDetails?.productCodeId}`,
                },
              ],
            };
            await sendNotification(notificationObj);
          }
        }
        showToast(result);
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    }
  };

  const generateFullData = (data, quantity, sampleData) => {
    const getSampleSizeFromRange = (lotSize, quantityAccepted) => {
      if (!lotSize || quantityAccepted == null) return false;
      const [min, max] = lotSize.split("-").map(Number);
      return quantityAccepted >= min && quantityAccepted <= max;
    };
    const updatedRecords = data?.map((row) => {
      const updatedParametersToBeInspected = row?.parametersToBeInspected?.map(
        (parameter) => {
          let size = 0;

          // Determine sample size based on type
          if (parameter?.type === "√n+1") {
            size = Math.round(Math.sqrt(quantity ?? 0)) + 1;
          } else if (parameter?.type === "Range") {
            const matchingRange = sampleData?.find(({ lotSize }) =>
              getSampleSizeFromRange(lotSize, quantity)
            );
            size = matchingRange ? parseInt(matchingRange?.sampleSize, 10) : 0;
          }

          // Update parameter
          return {
            ...parameter,
            observations:
              parameter?.observations?.length > 0
                ? parameter.observations
                : Array(parseInt(size)).fill(""),
            sampleSize: parameter?.sampleSize ?? parseInt(size),
            ...(["Random", "Percentage(%)"]?.includes(parameter?.type)
              ? {
                  isSubmittedObservation: false,
                  observationExtraValue: 0,
                }
              : {}),
          };
        }
      );

      // Update row
      return {
        ...row,
        parametersToBeInspected: updatedParametersToBeInspected, // Fixed typo
      };
    });
    return updatedRecords; // Optionally return updated records
  };

  return (
    <MainCard
      title="Final Inspection Form"
      secondary={
        <Stack
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          gap={1}
        >
          <Button variant="contained">Download</Button>

          <Button
            color="success"
            variant="contained"
            onClick={() =>
              navigate(
                `/production/final-split-bmr?id=${documentData?.productData?.bmrId}`
              )
            }
          >
            Show BMR
          </Button>
        </Stack>
      }
    >
      {openFinishedProductRegister ? (
        <ESign
          open={openFinishedProductRegister}
          setOpen={setOpenFinishedProductRegister}
          handleSubmit={handleESignUpdate}
        />
      ) : null}

      <ReportDynamicTable
        tableColumns={[
          ...(planColumns?.length > 0 ? planColumns : []),
          ...(tableColumns?.length > 0 ? tableColumns : []),
        ]}
        setTableColumns={setTableColumns}
        formColumns={formColumns}
        setFormColumns={setFormColumns}
        rows={data}
        setRows={setData}
        handleEsign={isOpen}
        setHandleEsign={setIsOpen}
        handleSubmit={handleSave}
        errors={errors}
        setErrors={setErrors}
        documentData={documentData}
        setDocumentData={setDocumentData}
        formatData={formatData}
        setFormatData={setFormatData}
        batchReleaseChecklist={batchReleaseChecklist}
        setBatchReleaseChecklist={setBatchReleaseChecklist}
        finalQuantity={finalQuantity}
        setFinalQuantity={setFinalQuantity}
        setOpenFinishedProductRegister={setOpenFinishedProductRegister}
        openFinishedProductRegister={openFinishedProductRegister}
      />
    </MainCard>
  );
};

export default withRouter(FinalInspectionForm);
