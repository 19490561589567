import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";
import InProcessPlanForm from "../InProcessInspection/InProcessPlanForm";
import InProcessPlanList from "../InProcessInspection/InProcessPlanList";
import MamGeneratePlan from "../MamGeneratePlan/MamGeneratePlan";
import FinalInspectionList from "../Final-Inspection/FinalInspectionList";
import FinalInspectionForm from "../Final-Inspection/FinalInspectionForm";
const YearList = Loadable(lazy(() => import("../YearList")));
const List = Loadable(lazy(() => import("../List")));
const IndependentPlan = Loadable(lazy(() => import("../IndependentInProcess/IndependentPlan")));
const InDependentInProcessList = Loadable(lazy(() => import("../IndependentInProcess/InDependentInProcessList")));

// sampling list
const SamplingList = Loadable(lazy(() => import("../Sampling-Plan/List")));
const SamplingManage = Loadable(lazy(() => import("../Sampling-Plan/Manage")));
// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const MAMRoutes = createRoute(
    "/",
    <AuthGuard>
        <MainLayout />
    </AuthGuard>,
    [
        createRoute(`${routeConstants.MAM_OF_PRODUCT}`, null, [
            createRoute("mam-generate-plan/:year", <MamGeneratePlan />),
            createRoute("year-list", <YearList />),
            createRoute("list/:year", <List />),
            createRoute("independent-inprocess/:year", <InDependentInProcessList />),
            createRoute("independent-form", <IndependentPlan />),
            createRoute("inprocess-form/:id", <InProcessPlanForm />),
            createRoute("inprocess-list/:year", <InProcessPlanList />),
            createRoute("final-inspection-list/:year", <FinalInspectionList />),
            createRoute("final-process-form/:id", <FinalInspectionForm />),
            // Nested Route for MAM
            // Nested Route for Sampling Size
            createRoute("sampling-plan", null, [
                createRoute("list/:year", <SamplingList />),
                createRoute("manage/:id?", <SamplingManage />),
            ]),
        ]),
    ]
);

export default MAMRoutes;
