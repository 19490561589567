import { useRoutes } from "react-router-dom";
import { useEffect, useState, lazy } from "react";
import {
  getConFigData,
  getItem,
  isNotNullOrUndefined,
  modifyQuestions,
  removeItem,
  setItem,
  verifyDomain,
} from "services/utils";
import Loadable from "components/Loadable";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
const Domain404 = Loadable(
  lazy(() => import("pages/maintenance/error/Domain404"))
);
import CATRoutes from "pages/CAT/utils/route";
import CPRoutes from "pages/CustomerProperty/utils/route";
import CRPDRoutes from "pages/CRPD/utils/route";
import PurchaseRoutes from "pages/Purchase/utils/route";
import InstallationRoutes from "pages/Installation/utils/InstallationRoutes";

// import MAMRoutes from "pages/Mam-of-Product/utils/route";
import MAMRoutes from "pages/Mam-Of-Products/utils/route";
import MAMEquipmentRoutes from "pages/Mam-of-equipment/utils/route";
import CONCPRoute from "pages/Control-of-Non-Conforming-Product/utils/route";
import TestRoutes from "pages/temp/route";
import WERoutes from "pages/Work-Environment/utils/route";
import FeedbackRoute from "pages/Feedback/utils/route";
import ServicingRoutes from "pages/CustomerService/utils/route";
import PreventiveMaintenanceRoutes from "pages/Preventive-Maintenance/utils/route";
import MRMRoutes from "pages/ManagementReviewMeeting/utils/route";
import IQARoutes from "pages/IQA/Utils/IQARoutes";
import InternalCommunicationRoute from "pages/Internal-Communication/utils/route";
import FormatRoute from "pages/Format/utils/route";
import ProdRoutes from "pages/Production/Utils/ProdRoutes";
import ProductRoutes from "pages/Product/utils/route";
import finishedProductRegisterRoute from "pages/finishedProductRegister/utils/route";
import CAPARoutes from "pages/CAPA/utils/routes";
import CCRoutes from "pages/CustomerComplaint/utils/routes";
import withRouter from "components/withRouter";
import QualityManualRoutes from "pages/standards/QualityManual/utils/route";
import ProcedureRoutes from "pages/standards/Procedure/utils/route";
import CODRoutes from "pages/ControlOfDocument/utils/route";
import ARRoutes from "pages/Advisory-Recall/Utils/routes";
import PVRoutes from "pages/Process-Validation/utils/routes";
import CWRBRoutes from "pages/Communication-with-regulatory-body/utils/routes";
import CORRoutes from "pages/Control-Of-Records/utils/route";
import PermissionRoute from "pages/Permission/utils/route";
import DynamicDocumentRoute from "pages/DynamicDocument/utils/route";
import { GET_DOCUMENT_NUMBERS } from "pages/DynamicDocument/utils/urlHelper";
import ChangeNoteRoute from "pages/Change-Note/utils/route";
import FormatStructureRoute from "pages/standards/Format/utils/route";
import DynamicFormRoute from "pages/Dynamic-Form/utils/route";

const ThemeRoutes = (props) => {
  const { userData, commonUrl, ApiServices, companyData, Constants } = props;
  const [isVerifiedDomain, setVerifiedDomain] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [documentData, setDocumentData] = useState(
  //   getItem(Constants?.DOCUMENT_NUMBERS)
  // );

  const configData = getItem(Constants.CONFIGDATA);

  const getDocumentNumbersData = async (clientQuestionsData) => {
    try {
      let uri = GET_DOCUMENT_NUMBERS;
      let result = await ApiServices.callServiceGet(uri);
      if (result?.data?.quality || result?.data?.procedure) {
        const allDocuments = [
          ...(result?.data?.quality || []),
          ...(result?.data?.procedure || []),
        ];
        const data = allDocuments?.map((item) => ({
          documentNumber: item?.number,
          documentName: item?.name,
          defaultName: item.defaultName,
          masterDocId: item.masterDocId,
        }));

      const updatedConfigData = {
        ...clientQuestionsData,
        documentNumbers: data,
      };

      setItem(Constants?.CONFIGDATA, updatedConfigData);

      }
    } catch (error) {
      console.error("GET_ALL_CREATED_PROCEDURE API ERROR", error);
    }
  };

  useEffect(() => {
    if (
      // !isNotNullOrUndefined(getItem(Constants?.DOCUMENT_NUMBERS)) &&
      isNotNullOrUndefined(userData)
    ) {
      getQuestionByClient();
    }
    if (isNotNullOrUndefined(userData)) {
      getDocumentNumbersData();
    }
  }, [userData]);

  const verifyDomainAsync = async () => {
    setLoading(true);
    try {
      const data = await verifyDomain();
      setLoading(false);

      if (data?.validDomain) {
        setVerifiedDomain(true);
        if (isNotNullOrUndefined(data?.data)) {
          setItem(Constants.KEY_COMPANY_DATA, data?.data);
        }
      } else {
        setVerifiedDomain(false);
        removeItem(Constants.KEY_COMPANY_DATA);
      }
    } catch (error) {
      console.error("verifyDomain Error=>", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isNotNullOrUndefined(companyData)) {
      verifyDomainAsync();
    }
  }, []);

  const getQuestionByClient = async () => {
    try {
      let reqObj = {
        standardName: "ISO13485",
        orgId: userData?.orgId,
      };
      let uri = commonUrl?.GET_CLIENT_QUESTION;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if (result?.response) {
        let data = modifyQuestions(result?.result?.data, reqObj?.standard);
        let clientQuestionsData = getConFigData(data);
        await getDocumentNumbersData(clientQuestionsData);
      }
    } catch (error) {
      console.error("GET_CLIENT_QUESTION API ERROR", error);
    }
  };

  const routes = useRoutes([
    LoginRoutes,
    MainRoutes,
    CATRoutes,
    CPRoutes,
    CRPDRoutes,
    PVRoutes,
    PurchaseRoutes,
    InstallationRoutes,
    CWRBRoutes,
    MAMRoutes,
    CONCPRoute,
    TestRoutes,
    WERoutes,
    MAMEquipmentRoutes,
    CCRoutes,
    FeedbackRoute,
    ServicingRoutes,
    PreventiveMaintenanceRoutes,
    ARRoutes,
    MRMRoutes,
    IQARoutes,
    InternalCommunicationRoute,
    FormatRoute,
    ProdRoutes,
    ProductRoutes,
    finishedProductRegisterRoute,
    CAPARoutes,
    QualityManualRoutes,
    ProcedureRoutes,
    CODRoutes,
    CORRoutes,
    PermissionRoute,
    DynamicDocumentRoute,
    ChangeNoteRoute,
    FormatStructureRoute,
    DynamicFormRoute,
  ]);

  if (loading) {
    return routes;
  } else if (isVerifiedDomain) {
    return routes;
  } else {
    return <Domain404 />;
  }
};

export default withRouter(ThemeRoutes);
