// make array of object for equipment name and id merge
export const transformEquipmentData = (equipmentArray) => {
  console.log("🚀 ~ transformEquipmentData ~ equipmentArray:", equipmentArray);
  return equipmentArray?.map((item) => {
    console.log("🚀 ~ returnequipmentArray?.map ~ item:", item);
    const { equipmentName, equipmentId } = item || {};
    return {
      name: `${equipmentName}-${equipmentId}`,
      _id: item?._id,
    };
  });
};

// Helper function to determine if quantityAccepted is within a range
const getSampleSizeFromRange = (lotSize, quantityAccepted) => {
  if (!lotSize || quantityAccepted == null) return false;
  const [min, max] = lotSize.split("-").map(Number);
  return quantityAccepted >= min && quantityAccepted <= max;
};

export const processInspectionDataWithRange = (dataArray, sampleData) => {
  if (!Array.isArray(dataArray) || !sampleData) return null; // Ensure valid inputs

  // Function to get the sample size based on type and quantityAccepted
  const getSampleSize = (type, quantityAccepted) => {
    if (type === "√n+1") {
      return Math.ceil(Math.sqrt(quantityAccepted)) + 1;
    }
    if (type === "Range") {
      const matchingRange = sampleData.find(({ lotSize }) =>
        getSampleSizeFromRange(lotSize, quantityAccepted)
      );
      return matchingRange ? parseInt(matchingRange?.sampleSize, 10) : 0;
    }
    return 0; // Default sample size for unknown types
  };

  // Process each item in the dataArray
  return dataArray
    .map((data) => {
      const { quantityAccepted, parametersToBeInspected } = data;
      if (!quantityAccepted || !parametersToBeInspected) return null; // Skip invalid entries

      const updatedParameters = parametersToBeInspected?.map((parameter) => {
        const sampleSize = getSampleSize(
          parameter?.type || "",
          quantityAccepted
        );

        if (
          parameter?.type === "Percentage(%)" ||
          parameter?.type === "Random"
        ) {
          if (sampleSize === 0) {
            if (parameter?.isSubmittedObservation) {
              // When observations need to be submitted
              const observationCount =
                parameter?.type === "Percentage(%)"
                  ? Math.ceil(
                    (parameter?.observationValue / 100) * quantityAccepted
                  )
                  : parameter?.observationValue;

              return {
                ...parameter,
                observations: parameter?.observations?.length
                  ? parameter?.observations
                  : Array(observationCount).fill(""),
                isSubmittedObservation: true,
                sampleSize: parameter?.sampleSize ?? observationCount,
              };
            }

            // Default behavior when observations are not submitted
            return {
              ...parameter,
              observationValue: 0,
              isSubmittedObservation: false,
              observations: [],
              sampleSize: 0,
            };
          }
        }
        // Default behavior for other types
        return {
          ...parameter,
          sampleSize: parameter?.observations?.length || sampleSize,
          observations:
            parameter?.observations?.length > 0
              ? parameter?.observations
              : Array(sampleSize).fill(""),
        };
      });

      return {
        ...data,
        parametersToBeInspected: updatedParameters,
      };
    })
    .filter(Boolean); // Remove null entries
};

export const generateObervations = (
  observations,
  type,
  quantity,
  sampleData,
  extraValue
) => {
  let size;
  if (type === "√n+1") {
    size = Math.round(Math.sqrt(quantity ?? 0)) + 1;
  } else if (type === "Percentage(%)") {
    size = Math.round((extraValue / 100) * quantity ?? 0);
  } else if (type === "Range") {
    const matchingRange = sampleData?.find(({ lotSize }) =>
      getSampleSizeFromRange(lotSize, quantity)
    );
    size = matchingRange ? parseInt(matchingRange?.sampleSize, 10) : 0;
  } else if (type === "Random") {
    size = extraValue ? parseInt(extraValue) : 0;
  }
  let finalArray = Array(size ?? 0).fill("") || null;
  return observations?.length > 0 ? observations : finalArray;
};
