import React, { useEffect, useState } from "react";
import { Button, Grid, Box } from "@mui/material";
import AuthWrapper from "pages/auth/template/AuthWrapper";
import * as url from "./utils/urlHelper";
import withRouter from "components/withRouter";
import {
  getExternalFormatDataById,
  showToast,
  validateColumns,
} from "services/utils";
import SkeletonTable from "components/SkeletonTable";
import DynamicTableForm from "components/reusables/DynamicTableForm";
import DynamicTableCustomerFeedback from "./DynamicTableCustomerFeedback";

const ServicingForm = (props) => {
  const { params, navigate } = props?.router,
    { ApiServices, FormatConstants } = props;
  const { id, orgId } = params;
  const [serviceData, setServiceData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [formatData, setFormatData] = useState([]);
  const [customerDetailsColumns, setCustomerDetailsColumns] = useState([]);
  const [customerDetailsError, setCustomerDetailsError] = useState([]);
  const [customerServicingColumns, setCustomerServicingColumns] = useState([]);
  const [customerServicingOptions, setCustomerServicingOptions] = useState([]);
  const [customerServicingRows, setCustomerServicingRows] = useState([]);
  const [customerServicingError, setCustomerServicingError] = useState([]);
  const [customerServicingDetailsColumns, setCustomerServicingDetailsColumns] =
    useState([]);
  const [customerServicingDetailsError, setCustomerServicingDetailsError] =
    useState([]);

  console.log("customerServicingRows", customerServicingRows);
  const handleSubmit = async () => {
    try {
      const rowsLength = Object.keys(customerServicingRows)?.length;

      if (rowsLength === 0 || rowsLength === 1) {
        showToast("Please Select All Rows For Overall Satisfaction of Service");
      } else if (rowsLength !== 3) {
        showToast(
          "Please Select Exactly All Rows For Overall Satisfaction of Service"
        );
      }

      const validationCustomerDetailsError = validateColumns(
        customerDetailsColumns
      );
      if (Object.keys(validationCustomerDetailsError)?.length > 0) {
        setCustomerDetailsError(validationCustomerDetailsError);
      }

      const validationCustomerServicingDetailsColumns = validateColumns(
        customerServicingDetailsColumns
      );
      if (Object.keys(validationCustomerServicingDetailsColumns)?.length > 0) {
        setCustomerServicingDetailsError(
          validationCustomerServicingDetailsColumns
        );
      }

      if (
        Object.keys(validationCustomerDetailsError)?.length > 0 ||
        Object.keys(validationCustomerServicingDetailsColumns)?.length > 0 ||
        rowsLength !== 3
      ) {
        return;
      }

      let reqObj = {
        parentId: id,
        orgId: orgId,
        formatName: "Customer Service form",
        documentName: "Customer Service from",
        prefixType: "FormatPrefixes",
        subType: "Generate-Link",
        document: {
          customerDetails: {
            columns: customerDetailsColumns,
          },
          customerServicingData: {
            columns: customerServicingColumns,
            rows: customerServicingRows,
            options: customerServicingOptions,
          },
          customerServicingDetails: {
            columns: customerServicingDetailsColumns,
          },
        },
      };

      let uri = url.PUBLIC_FORM;

      const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if (result) {
        showToast(result);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // get format data
  const getFormatData = async () => {
    try {
      setIsLoading(true);
      let result = await getExternalFormatDataById(
        FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_FEEDBACK,
        orgId
      );
      setFormatData(result?.structuredData ?? {});
      if (result?.structuredData?.length) {
        let columnData = result?.structuredData;
        const customerDetails = columnData?.find(
          (itm) => itm?.defaultName === "Customer details"
        )?.columns;
        setCustomerDetailsColumns(customerDetails);
        const servicingData = columnData?.find(
          (itm) => itm?.defaultName === "Overall satisfaction of service"
        );
        setCustomerServicingColumns(servicingData?.columns);
        setCustomerServicingOptions(servicingData?.options);
        setCustomerServicingRows(servicingData?.rows);
        const servicingDetails = columnData?.find(
          (itm) => itm?.defaultName === "servicing data"
        )?.columns;
        setCustomerServicingDetailsColumns(servicingDetails);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFormatData();
  }, []);

  console.log("customerDetailsColumns", customerDetailsColumns);
  return (
    <AuthWrapper isForm={true}>
      <>
        <Box mb={2}>
          {isLoading ? (
            <SkeletonTable columnCount={2} />
          ) : (
            customerDetailsColumns && (
              <DynamicTableForm
                columns={customerDetailsColumns}
                setColumns={setCustomerDetailsColumns}
                setErrors={setCustomerDetailsError}
                errors={customerDetailsError}
                isStrictDataForRow={true}
              />
            )
          )}
        </Box>

        <Box mb={2}>
          {isLoading ? (
            <SkeletonTable columnCount={2} />
          ) : (
            customerServicingColumns && (
              <DynamicTableCustomerFeedback
                columns={customerServicingColumns}
                setColumns={setCustomerServicingColumns}
                rows={customerServicingRows}
                setRows={setCustomerServicingRows}
                setErrors={setCustomerServicingError}
                options={customerServicingOptions}
                setOptions={setCustomerServicingOptions}
                errors={customerServicingError}
                isStrictDataForRow={true}
              />
            )
          )}
        </Box>

        <Box mb={2}>
          {isLoading ? (
            <SkeletonTable columnCount={2} />
          ) : (
            customerServicingDetailsColumns && (
              <DynamicTableForm
                columns={customerServicingDetailsColumns}
                setColumns={setCustomerServicingDetailsColumns}
                setErrors={setCustomerServicingDetailsError}
                errors={customerServicingDetailsError}
                isStrictDataForRow={true}
              />
            )
          )}
        </Box>
      </>
      <Grid item xs={12} justifyContent="flex-end" display="flex" gap={2}>
        <Button onClick={() => navigate("/")} variant="contained" color="error">
          Cancel
        </Button>

        <Button
          variant="contained"
          color="success"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </Grid>
    </AuthWrapper>
  );
};
export default withRouter(ServicingForm);
