import { lazy } from "react";
import GuestGuard from "utils/route-guard/GuestGuard";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import { routeConstants } from "services/keyConstants";

const AuthCenter = Loadable(lazy(() => import("pages/auth/AuthCenter")));
const GuestFeedback = Loadable(
  lazy(() => import("pages/Feedback/GuestFeedback"))
);
const GuestOrderForm = Loadable(
  lazy(() => import("pages/CRPD/GuestOrderForm"))
);
const SupplierServiceProviderSelectionForm = Loadable(
  lazy(() => import("pages/Purchase/SupplierServiceProviderSelectionForm"))
);
const InstallationExternalFormat = Loadable(
  lazy(() => import("pages/Installation/InstallationExternalFormats"))
);
const ServiceForm = Loadable(
  lazy(() => import("pages/CustomerService/ServicingForm"))
);

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: "/",
          element: <AuthCenter />,
        },
        {
          path: "feedback-guest/:orgId/:id",
          element: <GuestFeedback />,
        },
        {
          path: "order-form/:id/:orgId",
          element: <GuestOrderForm />,
        },
        {
          path: "purchase/supplier-service-form",
          element: <SupplierServiceProviderSelectionForm />,
        },
        {
          path: "installation-external-format",
          element: <InstallationExternalFormat />,
        },
        {
          path: `${routeConstants.CUSTOMER_SERVICE}/service-form/:id/org-id/:orgId`,
          element: <ServiceForm />,
        },
      ],
    },
  ],
};

export default LoginRoutes;
