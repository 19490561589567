//auth
export const LOGIN = "/auth/login";
export const CLIENTLOGIN = "/client/auth/login";

// Dashboard
export const GET_DASHBOARD_COUNT = "/client/users/get-count";

// Document

export const GET_DOCUMENT_LIFE_CYCLE = "/masterTable/get-dynamic";

//Category
export const GET_ALL_CATEGORIES = "/category";
export const CREATE_CATEGORY = "/category";
export const UPDATE_CATEGORY = "/category/";

//questions
export const CREATE_QUESTION = "/question";
export const UPDATE_QUESTION = "/question/";
export const CREATE_QUESTION_MCMC = "/question/mcmc";
export const GET_ALL_QUESTION = "/question";
export const GET_QUESTIONS_BY_CATEGORYID = "/question/parent";
export const GET_PARENT_QUESTION = "/question/list";
export const GET_SUB_QUESTION = "/question/sub";
export const GET_QUESTION_LIST = "/question/get-list";
export const GET_QUESTION_BY_ID = "/question/";

//choices
export const DELETE_CHOICE = "/choice/";
export const GET_CHOICES = "/choice/question";
export const GET_CHOICES_BY_QUESTION_ID = "/choice/question";

//product
export const GET_ALL_PRODUCTS = "/product";
export const CREATE_PRODUCT = "/product";
export const GET_CLIENT_PRODUCT_BY_ID = "/client/product/";
export const UPDATE_PRODUCT = "/product/";

//productCategory
export const GET_ALL_CATEGORIES_BY_PRODUCT_ID = "/product-category/list";

//productCategory

//template
export const CREATE_TEMPLATE = "/template";
export const GET_ALL_TEMPLATE = "/template";
export const GET_TEMPLATE_BY_ID = "/template/";
export const UPDATE_TEMPLATE = "/template/";
export const SUBMIT_ANSWER = "/client/document/generate";

//client document
export const GET_OUTPUT = "/client/document/view";

//constants
export const CONSTANT_CHECK = "/constant/check";
export const GET_CONSTANTS_BY_CATEGORY = "/constant/list";

// verified-domain
export const CHECK_VERIFIED_DOMAIN = "/client/organization/verify-domain";

// verify Client
export const VERIFY_EMAIL = "/client/auth/verify";

// Save password
export const SAVE_PASSWORD = "/client/auth/update-password";

//Client login
export const SIGN_IN = "/client/auth/login";

//Get client products
export const GET_CLIENTS_PRODUCTS_BY_ORGANIZATION_ID = "/client/organization/";

//Update Client
export const UPDATE_USERS = "/client/users/";
export const GET_USERS = "/client/users/";

//Product All Document By Org and ProdcutId
export const GET_DOCUMENT_BY_PRODUCT_AND_ORG_ID = "/client/document/view-all";
export const GET_DOCUMENT_BY_PRODUCT_ID = "/client/document/";
export const UPDATE_DOCUMENT = "/client/document/";

//STANDARDS
export const LIST_OF_ASSIGN_STANDARD = "/client/users/get-standards";
export const GET_QUESTION_BY_STANDARD = "/client/qa/update";

//CLIENT QUESTIONS
export const CREATE_CLIENT_QUESTION = "/client/qa";
export const GET_CLIENT_QUESTION = "/client/qa/by-client";
export const UPDATE_ANSWER = "/client/qa/update";
export const DELETE_ANSWER_WITH_PROCEDURE = "/procedure/delete-by-question";
export const GET_COUNT_QA = "/client/qa/get-count";

// Quality Manual
export const ADD_MANUAL = "/qmsSet/qualityManual";
export const UPDATE_STATUS = "/qmsSet/qualityManual/update-status";
export const UPDATE_MANUAL = "/qmsSet/qualityManual/update";
export const GET_ALL_MANUAL = "/qmsSet/qualityManual/get";
export const GET_MANUAL_BY_ID = "/qmsSet/qualityManual/get-manual";
export const MANUAL_LIST = "/qmsSet/qualityManual";
export const ADD_STANDARD = "/standard/";
export const UPDATE_QM = "/qmsSet/qualityManual/";
export const DOWNLOAD_MANUAL = "/qmsSet/qualityManual/download";
export const DOWNLOAD_IOP = "/qmsSet/qualityManual/download-iop";
export const VERSION_LIST = "/common/versions";
export const GET_MANUAL_BY_DOCID = "/qmsSet/qualityManual/getManualByDocId";
export const ESIGN = "/quality-manual/add-signature-user";

// Download Docs
export const DOWNLOAD_DOCUMENT = "/common/download";

//Notifications
export const GET_NOTIFICATIONS = "/notifications/get";
export const DELETE_SINGLE_NOTIFICATION = "/notifications/delete-by-id";

//Headers
export const CREATE_HEADER = "/header/get";
export const SAVE_HEADER = "/header/update";
export const SAVE_HEADER_WITH_MEDIA = "/header/update-with-image";
export const GET_HEADER = "/header/";
export const GET_THEME = "/header/";

// Document Number Method
export const CREATE_DOCUMENT_NUMBER_METHOD = "/docNumSequence";
export const UPDATE_DOCUMENT_NUMBER_METHOD = "/docNumSequence/";

//Procedure
export const GET_ALL_CREATED_PROCEDURE = "/qmsSet/procedure/get";
export const GET_PROCEDURE_BY_DOCUMENT_ID = "/qmsSet/procedure/get-procedure";
export const GET_PROCEDURE_BY_VERSION = "/qmsSet/procedure/get-version";
export const UPDATE_PROCEDURE = "/qmsSet/procedure/update";
export const GET_ALL_CREATED_PROCEDURE_BY_ID = "/qmsSet/procedure/getprocedure";
export const ESIGNPROCEDURE = "/procedure/add-signature";
export const ESIGN_FORMATS = "/formats/sign";
export const GET_BY_ID_PROCEDURE = "/procedure/"

// Format
export const GET_FORMAT_BY_PROCEDURE = "/qmsSet/format/get-format";
export const GET_FORMAT_BY_FORMAT_ID = "/qmsSet/format/get-data";
export const UPDATE_FORMAT_BY_FORMAT_ID = "/qmsSet/format/update";
export const GET_FORMATS_BY_PROCEDURE_ID = "/qmsSet/format/getformats";

//Roles
export const CREATE_USER_ROLE = "/role";
export const GET_ALL_USER_ROLES = "/role/by-orgId";
export const GET_ALL_ROLES_API = "/client/users/designation";

//Client
export const ADD_CLIENT_USERS = "/client/users";
export const UPDATE_CLIENT_USERS = "/client/users/";
export const GET_ALL_CLIENTS_USER = "/client/users/by-orgId";
export const UPDATE_CLIENT_RESPONSIBILITY =
  "/client/users/update-responsibility";
export const GET_ALL_RESPONSIBILITY_DOWNLOAD =
  "/qmsSet/qualityManual/all-responsibility";
export const GET_CLIENT_BYID = "/client/users/";
export const GET_CLIENT_BY_ID = "/client/users/";
export const UPDATE_CLIENT_BY_ID = "/client/users/";
export const UPDATE_CLIENT_WITH_IMAGE = "/client/users/add/";
export const UPDATE_CLIENT_PASSWORD = "/client/users/updatePass/";
export const UPDATE_CATEGORY_CLIENT_BY_ID = "/client/users/";
export const UPDATE_CLIENT_EMAIL = "/client/users/";

//Master Table
export const CREATE_MASTER_TABLE = "/masterTable";
export const UPDATE_MASTER_TABLE = "/masterTable/update";
export const GET_MASTER_TABLE_ID = "/masterTable/master-table/";

// Doc Change Note
export const CREATE_DOC_CHANGE_NOTE = "/docChangeNote";
export const GET_CHANGE_NOTE_BY_ID = "/docChangeNote/";
export const GET_DOC_CHANGE_NOTE_DATA = "/docChangeNote/get-changenote";
export const CREATE_ABSOLUTE_VERSION = "/docChangeNote/absolute-version";
export const MAKE_VERSION = "/docChangeNote/make-version";
export const DOWNLOAD_CHANGENOTE = "/docChangeNote/download";

export const BY_FORMAT_NUMBER = "/docChangeNote/by-format-number";

export const GET_DOC_CHANGE_NOTE_DATA_BY_ORG =
  "/docChangeNote/get-changenote-by-name";
export const UPDATE_CHANGE_NOTE = "/docChangeNote/update-specific";
export const GET_FORMAT_LIST = "/docChangeNote/format-list";

export const UPDATE_CHANGE_NOTE_SPECIFIC_ESIGN =
  "/docChangeNote/update-sign-history";

//Features
export const CREATE_FEATURES = "/features";
export const GET_MRM_HISTORY = "/features/get-feature";
export const UPDATE_MRM_HISTORY = "/features/";
export const GET_FEATURE_BY_DOUCMENTID = "/features/get-by-trash";

// client photo
export const UPDATE_CLIENT = "/client/organization/";
export const GET_CLIENT = "/client/organization/";

//New Formats
export const CREATE_FORMATS = "/proformats";
export const CREATE_FORMATS_WITH_MEDIA = "/proformats/media";
export const GET_FORMATS_BY_FORMATNAME = "/proformats/get-format";
export const CREATE_FORMATS_CLONE = "/proformats/clone-format";
export const GET_FEEDBACK_BY_TYPE = "/proformats/get-proformat-by-type";
export const GET_FEEDBACK_BY_ID = "/proformats/get-proformat-by-type-id/";

//childformats
export const CREATE_CHILD_FORMATS = "/childformats";
export const GET_CHILD_FORMATS = "/childformats/get-child-format";
export const GET_CHILD_FORMATS_DATA = "/childformats/get-child-format-single";
export const UPDATE_CHILD_FORMAT_DATA = "/childformats/update-nested-format";
export const GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER =
  "/childformats/get-specific-format-obj";
export const DOWNLOAD_CHILD_FORMATS = "/childformats/download";
export const GET_SPECIFIC_CHILD_DATA =
  "/childformats/get-child-format-single-by-key";

export const PROCEDURE_VERSION_LIST = "/common/versions";
export const DOWNLOAD_PROCEDURE = "/qmsSet/procedure/download";
export const DYNAMIC_PUSH_PULL = "/childformats/dynamic-push-pull";
export const GET_All_OBJECTS = "/childformats/all-objects";

//For Notification
export const Delete_All_Notifications = "/notifications/delete-all";
export const Delete_Notification_By_Id = "/notifications/delete-by-id/";

// child format public
export const CREATE_FORMATS_PUBLIC = "/childformats/public-format";

// url to use in all required pages
// export const GET_ALL_DOCUMENT_NUMBER = "/childformats/get-format-numbers";
export const GET_ALL_DOCUMENT_NUMBER = "/masterTable/get";

//customer Complaints
export const GET_FORMAT_BY_CLIENT_ID = "/childformats/get-format-by-client-id";
export const POST_FILE = "/childformats/image";
export const GET_CHILD_FORMAT_BY_NAME = "/childformats/get-format-by-name";
export const GET_NOTIFICATIONS_QAHEAD =
  "/notifications/send-notification-specific";

// filtering user data based on the Quality Manual's Organogram
export const FILTER_USER_DATA_BY_ORGANOGRAM =
  "/qmsSet/qualityManual/unique-data-organogram";
export const GET_HEAD_DATA_BY_ORGANOGRAM =
  "/qmsSet/qualityManual/head-data-organogram";

export const GET_TOPMGMT_DATA_BY_ORGANOGRAM =
  "/qmsSet/qualityManual/topManagement-data-organogram";

// Customer Properties
export const DOWNLOAD_CP = "/control-of-customer-property/download";

// Notification

export const SEND_NOTIFICATIONS = "/notifications/send-notification-specific";
export const SEND_SPECIFIC_NOTIFICATIONS = "/notifications/send-specific";
export const SEND_NOTIFICATIONS_TO_ALL = "/notifications/send-to-all";

export const GET_PRODUCT_NAME = `/product/get-name`;
export const GET_CHILD_PRODUCT = `/product/`;

//For Rbac
export const CREATE_ROLE_BY_DEFAULT = "/role/create-by-default";
export const GET_PERMISSION_FOR_USER = "/role/get-permissions-for-users";
export const USERS_LIST_BY_SELECTED_FIELDS = `/client/users/get-user-list`;

//for Master Table

export const GET_MASTER_TABLE_DATA = `/master-doc-module/get`;
export const GET_USER_DEPARTMENT_WISE = `/organogram/get-group`;
export const UPDATE_MASTER_DOC_TABLE = `/master-doc-module/`;

// Doc Number Method
export const GET_DESCRIPTION_FOR_DOCUMENT_NUMBERS = `/docNumSequence/get-data`;

//PROCEDURE NAME
export const GET_PROCEDURE_NAME = "/master-doc-module/get-procedures";

export const GET_ALL_PROCEDURE_LIST = "/quality-manual/get-procedure-list";

//get assigned procedure
export const GET_ASSIGNED_PROCEDURE =`/client/organization/get-procedures-default`;

// get format
export const GET_FORMAT = '/common/get-format';

export const GET_EXTERNAL_FORMAT = '/common/get-external-format';
