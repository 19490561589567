import {
    Button,
    FormControl,
    MenuItem,
    TextField,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Stack,
    FormHelperText,
} from "@mui/material";
import MainCard from "components/MainCard";
import withRouter from "components/withRouter";
import React, { useEffect, useState } from "react";
import { FormatConstants } from "services/keyConstants";
import { buildUrl, getDocumentLifeCycleForMasterDoc, getFormatDataById } from "services/utils";
import * as url from '../utils/urlHelper'
import { Add, Delete } from "@mui/icons-material";

const IncomingInspectionPlan = (props) => {
    const {
        ApiServices,
        userData,
        onChangeDownloadState,
        // FormatConstants,
        MasterDocumentTypeConstants,
        handleDownload,
        handleSubmit,
        year
    } = props;

    const materialsState = [
        "Stainless steel 316L",
        "Titanium Gred 5",
        "Titanium Gred 23",
        "Uhwpe",
    ];

    const [data, setData] = useState([
        {
            product: "",
            rows: [
                {
                    process: "",
                    methodOfInspection: "",
                    specification: "",
                    specificationWithTolerance: "",
                    sampleSize: "",
                    acceptanceCriteria: "",
                },
            ],
        }
    ])
    const [documentData, setDocumentData] = useState({})
    const [columns, setColumns] = useState([])
    const [errors, setErrors] = useState({})
    const validateData = () => {
        let isValid = true;
        const newErrors = {};

        data?.forEach((table, tableIndex) => {
            const tableErrors = {};
            if (!table?.product?.trim()) {
                isValid = false;
                tableErrors.product = "Product is required";
            }
            table?.rows?.forEach((row, rowIndex) => {
                const rowErrors = {};
                columns?.forEach((column) => {
                    if (column?.validation?.required && !row[column.value]?.trim()) {
                        // Add an error for this field
                        isValid = false;
                        rowErrors[column?.value] = column.validation.msg || `${column.name} is required`;
                    }
                });
                if (Object.keys(rowErrors)?.length > 0) {
                    tableErrors[rowIndex] = rowErrors;
                }
            });
            if (Object.keys(tableErrors).length > 0) {
                newErrors[tableIndex] = tableErrors;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    const handleAddTable = () => {
        const newTable = {
            documentName: "Incoming Inspection Plan",
            product: "", // Default empty product
            rows: [
                {
                    process: "",
                    methodOfInspection: "",
                    specification: "",
                    specificationWithTolerance: "",
                    sampleSize: "",
                    acceptanceCriteria: "",
                },
            ],
        };
        setData([...data, newTable]);
    };

    const handleDeleteTable = (index) => {
        const updatedData = [...data];
        updatedData.splice(index, 1);
        setData(updatedData);
    };

    const handleRemoveRow = (tableIndex, rowIndex) => {
        setData((prevData) => {
            const updatedData = [...prevData];
            const rows = updatedData[tableIndex]?.rows;

            if (rows && rows.length > 1) {
                rows.splice(rowIndex, 1); // Remove the row at the specified index
            }

            return updatedData;
        });
    }

    const handleAddRow = (index, rowIndex) => {
        const updatedData = [...data];
        const defaultRow = {
            process: "",
            methodOfInspection: "",
            specification: "",
            specificationWithTolerance: "",
            sampleSize: "",
            acceptanceCriteria: "",
        };
        updatedData[index]?.rows?.splice(rowIndex + 1, 0, defaultRow);
        setData(updatedData);
    };

    const handleProductChange = (e, index) => {
        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            product: e?.target?.value,
        };
        setData(updatedData);

        // Remove error for the updated product field
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (updatedErrors[index]?.product) {
                delete updatedErrors[index].product; // Remove the product error for this index
                if (Object.keys(updatedErrors[index]).length === 0) {
                    delete updatedErrors[index]; // Remove the table index if no other errors exist
                }
            }
            return updatedErrors;
        });
    };


    const handleChangeIncoming = (e, rowIndex, tableIndex) => {
        const { name, value } = e?.target;

        // Update the specific row in the specific table
        setData((prevData) => {
            const updatedData = [...prevData]; // Create a copy of the data array
            updatedData[tableIndex].rows[rowIndex][name] = value; // Update the specific field
            return updatedData;
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            if (updatedErrors[tableIndex]?.[rowIndex]?.[name]) {
                delete updatedErrors[tableIndex][rowIndex][name]; // Remove the specific field error
                if (Object.keys(updatedErrors[tableIndex][rowIndex]).length === 0) {
                    delete updatedErrors[tableIndex][rowIndex]; // Remove the row error if empty
                }
                if (Object.keys(updatedErrors[tableIndex]).length === 0) {
                    delete updatedErrors[tableIndex]; // Remove the table error if empty
                }
            }

            return updatedErrors;
        });
    };

    useEffect(() => {
        getData()
        // getFormatData()
    }, [])

    const getFormatData = async () => {
        try {
            let result = await getFormatDataById(
                FormatConstants.MAMOFPRODUCT.INCOMING_INSPECTION_PLAN
            );
            if (result?.structuredData?.length === 1) {
                setColumns(result?.structuredData?.[0]?.columns)
            } else {
                return [];
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getData = async () => {
        try {

            const masterDocData = await getDocumentLifeCycleForMasterDoc(
                FormatConstants.MAMOFPRODUCT.INCOMING_INSPECTION_PLAN,
                MasterDocumentTypeConstants.FORMAT
            );
            const queryParams = {
                masterDocId: masterDocData?._id,
                year: year,
            };
            const uri = buildUrl(url.GET_BY_MASTERDOCID, queryParams);
            const result = await ApiServices.callServiceGet(uri);
            if (result?.meta?.success) {
                setData(result?.data?.[0]?.document?.data ?? data);
                setColumns(result?.data?.[0]?.document?.columns ?? [])
                setDocumentData(result?.data?.[0]);
                if (!result?.data?.[0]?.document?.columns) {
                    getFormatData()
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <MainCard title="Incoming Inspection Plan" secondary={
            <>
                <Button variant="contained" onClick={handleDownload}>Download</Button>
            </>
        }>
            {data?.map((value, index) => (
                <TableContainer sx={{ margin: 1 }} key={index} component={Paper}>
                    <Table aria-label="dynamic table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography fontSize={15} fontWeight="bold">
                                        Product Name:{" "}
                                        <Typography
                                            fontSize={15}
                                            variant="span"
                                            fontWeight="normal"
                                        >
                                            {value?.product || "-"}
                                        </Typography>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <FormControl error={!!errors[index]?.product}>
                                        <Select
                                            sx={{ minWidth: "300px" }}
                                            value={value?.product || ""} // Bind the value to product _id
                                            onChange={(e) => handleProductChange(e, index)}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        >
                                            {materialsState?.map((option, i) => (
                                                <MenuItem key={i} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors[index]?.product && (
                                            <FormHelperText error>{errors[index]?.product}</FormHelperText>
                                        )}
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddTable}
                                        style={{
                                            marginLeft: "16px",
                                        }}
                                    >
                                        Add Product
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleDeleteTable(index)}
                                        style={{
                                            marginLeft: "16px",
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns?.map((column, colIndex) => {
                                    return <TableCell key={colIndex}>{column?.name}</TableCell>
                                })}
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {value?.rows?.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {columns?.map((column, colIndex) => (
                                        <TableCell key={colIndex}>
                                            {column?.type === "index" ? (
                                                rowIndex + 1
                                            ) : column?.type === "index" ?
                                                <TextField
                                                    label={column?.name}
                                                    name={column?.value}
                                                    value={row[column?.value] || ""}
                                                    onChange={(e) => handleChangeIncoming(e, rowIndex, index)}
                                                    error={!!errors[index]?.[rowIndex]?.[column.value]} // Check if there's an error for this field
                                                    helperText={errors[index]?.[rowIndex]?.[column.value]} // Display the error message
                                                />
                                                : column?.type === "index" ? (
                                                    <TextField
                                                        label={column?.name}
                                                        name={column?.value}
                                                        type={"number"}
                                                        value={row[column?.value] || ""}
                                                        onChange={(e) => handleChangeIncoming(e, rowIndex, index)}
                                                        error={!!errors[index]?.[rowIndex]?.[column.value]} // Check if there's an error for this field
                                                        helperText={errors[index]?.[rowIndex]?.[column.value]} // Display the error message
                                                    />
                                                ) : <TextField
                                                    label={column?.name}
                                                    name={column?.value}
                                                    value={row[column?.value] || ""}
                                                    onChange={(e) => handleChangeIncoming(e, rowIndex, index)}
                                                    error={!!errors[index]?.[rowIndex]?.[column.value]} // Check if there's an error for this field
                                                    helperText={errors[index]?.[rowIndex]?.[column.value]} // Display the error message
                                                />}
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        <Stack sx={{ display: "flex", flexDirection: "row" }} gap={1}>
                                            <IconButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleAddRow(index, rowIndex)}
                                            >
                                                <Add />
                                            </IconButton>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleRemoveRow(index, rowIndex)}

                                            ><Delete /></IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ))}
            <Button color="success" variant="contained" onClick={() => {
                if (validateData()) {

                    handleSubmit("Incoming Inspection Plan", { data, columns }, getData)
                }
            }}>Save</Button>
        </MainCard>
    );
};

export default withRouter(IncomingInspectionPlan);