import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants, commonRoutesConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "./urlHelper"

// Define lazy-loaded components
const Create = Loadable(lazy(() => import("../Create")));
const Feedback = Loadable(lazy(() => import("../Feedback")));
const FeedbackById = Loadable(lazy(() => import("../FeedbackById")));
const List = Loadable(lazy(() => import("../List")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });
// Define routes using lazy-loaded components
const FeedbackRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.FEEDBACK, null, [
      createRoute("create", <Create />),
      createRoute("view/:year", <Feedback />),
      createRoute(":id/:subId?", <FeedbackById />),
      createRoute("list/:year", <List />),
      createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
        uri={url.GET_BY_YEAR}
        title={routeConstants.FEEDBACK}
        isCreate={true}
        createRoute={{
          path: `/${routeConstants.FEEDBACK}/create`,
        }}
        navigateToFolder={`/${routeConstants.FEEDBACK}/list/`}
      />),
    ]),
  ]
);

export default FeedbackRoute;
