import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, Skeleton } from "@mui/material";
import MainCard from "components/MainCard";
import {
  downloadFormat,
  getFormatDataById,
  getTitle,
  showToast,
  validationDynamicTable,
} from "services/utils";
import * as url from "./utils/urlHelper";
import { CustomHooks } from "hooks/CustomHooks";
import withRouter from "components/withRouter";
import SkeletonTable from "components/SkeletonTable";
import DynamicTable from "components/reusables/DynamicTable";
import { FormatConstants } from "services/keyConstants";
import DynamicFormatStructure from "components/reusables/DynamicFormatStructure";

const ExternalListOfDoc = (props) => {
  const { userData, ApiServices, onChangeDownloadState } = props,
    { navigate } = props?.router;
  const { documentId, procedureId } = CustomHooks.useUrlParams();
  const handleSubmitDataRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState({});

  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});

  const handleDownload = async () => {
    let reqObj = {
      formatId: documentId,
    };
    let uri = url.DOWNLOAD_COD;
    await downloadFormat(uri, reqObj, onChangeDownloadState);
  };

  const updateDocument = async () => {
    try {
      const validationErrors = validationDynamicTable(rows, columns);
      if (Object.keys(validationErrors)?.length > 0) {
        setErrors(validationErrors);
        return;
      }
      const reqObj = {
        document: {
          rows: rows,
          columns: columns,
        },
      };
      let uri = url.GET_OR_UPDATE_EXTERNAL_LIST + documentId;
      let result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        getDocument();
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    }
  };

  const getDocument = async () => {
    try {
      let uri = url.GET_OR_UPDATE_EXTERNAL_LIST + `/${documentId}`;
      let result = await ApiServices.callServiceGet(uri);

      if (result?.meta?.success) {
        const datas = await getFormatData();
        let column = datas?.structuredData
          ?.filter((item) => item?.tableName === "List Of External Origin")
          ?.flatMap((item) => item?.columns);
        setColumns(
          result?.data?.document?.columns?.length
            ? result?.data?.document?.columns
            : column
        );

        setRows(
          result?.data?.document?.rows?.length
            ? result?.data?.document?.rows
            : rows
        );
        //   const documents = result?.data?.document ?? [];
        // const normalizedDocuments = Array.isArray(documents) ? documents : [documents];
        // const normalizedRows = normalizedDocuments.flatMap(doc => doc.rows || []);
        // const normalizedColumns = normalizedDocuments?.flatMap(doc => doc?.columns || []);
        // setRows(normalizedRows);
        // if (normalizedColumns && normalizedColumns.length > 0) {
        //   setColumns(normalizedColumns);
        // }
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFormatData = async () => {
    let result = await getFormatDataById(
      FormatConstants.COD.LIST_OF_EXTERNAL_ORIGIN
    );

    setData(result ?? null);
    return result;
  };

  useEffect(() => {
    getDocument();
  }, [procedureId, documentId]);

  return (
    <MainCard
      title={getTitle("External Origin Docs")}
      secondary={
        isLoading ? (
          <Skeleton
            variant="rectangular"
            height="30px"
            width="100px"
            sx={{
              borderRadius: "12px",
            }}
          />
        ) : (
          !userData?.externalUser && (
            <Button
              variant="contained"
              color="success"
              onClick={handleDownload}
            >
              Download
            </Button>
          )
        )
      }
    >
      <Grid container spacing={2}>
        {isLoading ? (
          <Grid item xs={12}>
            <SkeletonTable />
          </Grid>
        ) : (
          <>
            {data ? (
              <DynamicFormatStructure
                data={data?.structuredData?.filter(
                  (item) => item?.tableName === "List Of External Origin"
                )}
                columns={columns}
                rows={rows}
                setRows={setRows}
                setColumns={setColumns}
                setErrors={setErrors}
                errors={errors}
                options={{
                  isNumberDynamic: true,
                }}
              />
            ) : null}

            {/* <DynamicTable
              columns={columns}
              setColumns={setColumns}
              rows={rows}
              setRows={setRows}
              setErrors={setErrors}
              errors={errors}
              handleSubmitRef={handleSubmitDataRef}
            /> */}
            {!userData?.externalUser && (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap=".5rem"
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => updateDocument()}
                >
                  Save
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </MainCard>
  );
};

export default withRouter(ExternalListOfDoc);
