import { routeConstants } from "services/keyConstants";

const baseUrl = `/${routeConstants.CONTROL_OF_CUSTOMER_PROPERTY}`;

export const CREATE_LIST_OF_CP = `${baseUrl}/`;
export const GET_ALL_DATA = `${baseUrl}/get-all`;
export const GET_ALL_DATA_BY_TYPE = `${baseUrl}/get-dynamic`;
export const UPDATE_DATA = `${baseUrl}/update-specific`;
export const GET_BY_ID = `${baseUrl}/`;
export const GET_BY_YEAR = `${baseUrl}/year-list`;
export const DOWNLOAD_CP = `${baseUrl}/download`;
