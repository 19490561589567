import { ApiServices } from "./apiServices";
import * as organogramUrl from "pages/standards/QualityManual/Organogram/utils/urlHelper";

// for group using type
const groupBy = (data, key) =>
  data?.reduce((acc, item) => {
    const groupKey = item?.[key] || "Unknown";
    acc[groupKey] = [...(acc[groupKey] || []), item];
    return acc;
  }, {});

// for different by deparments
export const separateUsersByDepartment = (users) => {
  return users?.reduce((result, user) => {
    const department = user?.department;

    if (!result[department]) {
      result[department] = [];
    }

    result[department]?.push({
      designationId: user?.designationId,
      designation: user?.designation,
      userId: user?.userId,
      userName: user?.userName,
    });

    return result;
  }, {});
};

//seperate department and designation data
export const separateByDepartmentAndUser = (users) => {
  return users?.reduce((result, user) => {
    const { type, department } = user;
    // Ensure a grouping for the user's `type`
    if (!result?.[type]) {
      result[type] = {};
    }
    // Ensure a grouping for the department within the `type`
    if (!result?.[type]?.[department]) {
      result[type][department] = [];
    }
    // Push the user data into the appropriate group
    result[type][department]?.push({
      designationId: user?.designationId,
      designation: user?.designation,
      userId: user?.userId,
      userName: user?.userName,
    });

    return result;
  }, {});
};

//for seperate designations data by department
export const separateUsersByDesignation = (users) => {
  try {
    // Check if users is a valid array
    if (!Array.isArray(users)) {
      throw new Error("Input data is not an array.");
    }

    // Grouping logic
    return users?.reduce((result, user) => {
      const department = user?.department;

      // Ensure department exists before proceeding
      if (!department) {
        console.warn("User is missing a department:", user);
        return result; // Skip this user
      }
      // Ensure a grouping for the department
      if (!result[department]) {
        result[department] = [];
      }
      // Push the user data into the appropriate group
      result[department]?.push({
        userName: user?.userName || "",
        userId: user?.userId || "",
        departmentId: user?.departmentId || "",
        designation: user?.designation || "",
        designationId: user?.designationId || "",
        userDesignation: user?.userDesignation || "",
        type: user?.type || "",
      });

      return result;
    }, {});
  } catch (error) {
    console.error("Error in separateUsersByDesignation:", error.message);
    return {}; // Return an empty object in case of an error
  }
};

// for get data from passing through defaultDesignation
export const getUserDataByDesignation = async (
  reqObj,
  isGroup = true,
  isAuth = true,
  orgId
) => {
  try {
    let result = await ApiServices.callServicePostWithBodyData(
      isAuth
        ? organogramUrl.GET_USERS
        : organogramUrl.GET_USERS_WITHOUTAUTH + orgId,
      reqObj
    );
    if (result?.meta?.success) {
      if (isGroup) {
        let data = groupBy(result?.data, "type");
        return data;
      } else {
        return result?.data;
      }
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
};
