import { useState, useEffect } from "react";
import { TextField, Typography, Box, Button, Grid } from "@mui/material";
import CustomAccordionSkeleton from "components/CustomAccordionSkeleton";
import CustomAccordion from "components/CustomAccordian";
import Error404 from "components/reusables/404";
import * as url from "./utils/urlHelper";
import MainCard from "components/MainCard";
import TabsContainer from "components/TabsContainer";
import {
  downloadFormat,
  extractUserDetails,
  extractUserIdAndName,
  filterUserById,
  sendNotification,
  showToast,
} from "services/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getUserDataByDesignation } from "services/organogramServices";
import withRouter from "components/withRouter";
import CustomNotification from "components/CustomNotification";
import DynamicTableForm from "components/reusables/DynamicTableForm";
import { DepartmentConstants, routeConstants } from "services/keyConstants";
import DynamicTableCustomerFeedback from "./DynamicTableCustomerFeedback";

const ServiceRecordHistory = (props) => {
  const { params, navigate } = props?.router,
    {
      userData,
      ApiServices,
      onChangeDownloadState,
      TypeConstants,
      DepartmentConstants,
      routeConstants,
      selectedRole,
    } = props;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const year = params?.year;

  const getData = async () => {
    try {
      const reqObj = {
        year: year,
        demandedItem:
          tabValue === 0
            ? "Customer Service Record"
            : tabValue === 2
            ? "Servicing Feedback Form"
            : "Customer Servicing Details",
      };
      const result = await ApiServices.callServicePostWithBodyData(
        url.GET_ALL_SERVICE_FEEDBACK,
        reqObj
      );
      if (result?.meta?.success) {
        setData(result?.data?.list || []);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadClick = async (e, id) => {
    let reqObj = {
      formatId: id,
    };
    let uri = url.DOWNLOAD;
    await downloadFormat(uri, reqObj, onChangeDownloadState);
  };

  useEffect(() => {
    getData();
  }, [tabValue]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const tabLabels = [
    "Customer Record",
    "Customer Details",
    "Customer Servicing Feedback",
  ];

  console.log("🚀 ~ ServiceRecordHistory ~ data:", data);
  const tabContents = [
    () => (
      <CustomAccordionList
        isLoading={isLoading}
        data={data}
        expanded={expanded}
        handleAccordionChange={handleAccordionChange}
        type="Customer Service Record"
        isMakeDecisionButton={false}
        handleDownloadClick={handleDownloadClick}
        navigate={navigate}
        userData={userData}
        selectedRole={selectedRole}
      />
    ),
    () => (
      <CustomAccordionList
        isLoading={isLoading}
        data={data}
        expanded={expanded}
        handleAccordionChange={handleAccordionChange}
        type="Customer Servicing Details"
        setNotificationModalOpen={setNotificationModalOpen}
        notificationModalOpen={notificationModalOpen}
        handleSubmitNotification={handleSubmitNotification}
        setSelectedId={setSelectedId}
        isMakeDecisionButton={true}
        handleDownloadClick={handleDownloadClick}
        navigate={navigate}
        userData={userData}
        selectedRole={selectedRole}
      />
    ),
    () => (
      <CustomAccordionList
        isLoading={isLoading}
        data={data}
        expanded={expanded}
        handleAccordionChange={handleAccordionChange}
        type="Customer Servicing Feedback"
        setNotificationModalOpen={setNotificationModalOpen}
        notificationModalOpen={notificationModalOpen}
        handleSubmitNotification={handleSubmitNotification}
        setSelectedId={setSelectedId}
        isMakeDecisionButton={true}
        handleDownloadClick={handleDownloadClick}
        navigate={navigate}
        userData={userData}
        selectedRole={selectedRole}
      />
    ),
  ];

  const handleSubmitNotification = async (selectedUser) => {
    try {
      let reqObj = {
        id: selectedId,
        orgId: userData?.orgId,
        documentName: "Customer Servicing Details",
        prefixType: "FormatPrefixes",
        updateData: {
          "document.selectedUserForRecord": extractUserDetails(selectedUser),
          "document.makeDecision": true,
        },
      };
      let uri = url.UPDATE_SPECIFIC_DATA;
      const response = await ApiServices.callServicePutWithBodyData(
        uri,
        reqObj
      );
      if (selectedUser) {
        let notificationObj = {
          dataArray: [
            {
              recipients: filterUserById(selectedUser),
              msg: "Servicing is coming. Please prepare for the service record.",
              status: "Please Fill the service record",
              location: `${routeConstants.CUSTOMER_SERVICE}/service-record-history/${year}`,
            },
          ],
        };

        await sendNotification(notificationObj);
        getData();
      }

      showToast(response);
    } catch (error) {
      console.error("Error in handleSubmitNotification:", error);
    }
  };

  return (
    <>
      <MainCard title="Service Record History">
        <TabsContainer
          tabLabels={tabLabels}
          tabContents={tabContents}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
      </MainCard>
    </>
  );
};

const CustomAccordionList = ({
  isLoading,
  data,
  expanded,
  handleAccordionChange,
  type,
  setNotificationModalOpen,
  notificationModalOpen,
  handleSubmitNotification,
  setSelectedId,
  isMakeDecisionButton,
  handleDownloadClick,
  navigate,
  userData,
  selectedRole,
}) => {
  let filteredData = [];
  if (type === "Customer Servicing Feedback") {
    filteredData = data;
  } else {
    filteredData = data?.filter((item) => item.documentName === type);
  }

  const handleDesicionModal = (item) => {
    setSelectedId(item);
    setNotificationModalOpen(!notificationModalOpen);
  };

  return (
    <>
      {notificationModalOpen ? (
        <CustomNotification
          open={notificationModalOpen}
          setOpen={setNotificationModalOpen}
          isMultipleUser={true}
          handleSubmit={handleSubmitNotification}
          isStaticDepartment={[
            DepartmentConstants.SALES_AND_MARKETING_DEPARTMENT,
          ]}
        />
      ) : null}
      {isLoading ? (
        <CustomAccordionSkeleton />
      ) : filteredData?.length > 0 ? (
        filteredData?.map((item, index) => (
          <CustomAccordion
            key={item._id}
            expanded={expanded === index}
            onChange={handleAccordionChange(index)}
            summary={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography>
                  {type === "Customer Servicing Details"
                    ? item?.document?.columns?.find(
                        (column) => column?.value === "customerName"
                      )?.inputValue
                    : type === "Customer Servicing Feedback"
                    ? `Customer Feedback - ${index + 1}`
                    : `Customer Servicing Report - ${index + 1}`}
                </Typography>
                <Grid item>
                  {type === "Customer Servicing Details" &&
                    item.document?.makeDecision === false &&
                    item?.document?.selectedUser?.some((user) => {
                      return (
                        user?.userId === userData?._id &&
                        user?.designationId === selectedRole?.roleId
                      );
                    }) && (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleDesicionModal(item?._id)}
                      >
                        Make Decision
                      </Button>
                    )}
                  {type === "Customer Servicing Details" &&
                    item?.document?.isReportFiled === false &&
                    item?.document?.selectedUserForRecord?.some((user) => {
                      return (
                        user?.userId === userData?._id &&
                        user?.designationId === selectedRole?.roleId
                      );
                    }) && (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() =>
                          navigate(
                            `../../${routeConstants.CUSTOMER_SERVICE}/service-record/${item?._id}`
                          )
                        }
                      >
                        Fill the Record
                      </Button>
                    )}
                  {type === "Customer Service Record" && (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() =>
                        navigate(
                          `../../${routeConstants.CUSTOMER_SERVICE}/service-link`
                        )
                      }
                    >
                      Generate Feedback form
                    </Button>
                  )}
                </Grid>
                {/* )} */}
                <Button
                  variant="contained"
                  color="success"
                  onClick={(e) => handleDownloadClick(e, item?._id)}
                >
                  Download
                </Button>
              </div>
            }
            details={
              type === "Customer Servicing Details" ? (
                <>
                  (
                  <Typography component="div">
                    <DynamicTableForm
                      columns={item?.document?.columns}
                      isViewMode={true}
                    />
                  </Typography>
                  )
                </>
              ) : type === "Customer Servicing Feedback" ? (
                item?.document?.responces?.map((res) => (
                  <>
                    <DynamicTableForm
                      columns={res?.customerDetails?.columns}
                      isViewMode={true}
                    />

                    <DynamicTableCustomerFeedback
                      columns={res?.customerServicingData?.columns}
                      options={res?.customerServicingData?.options}
                      rows={res?.customerServicingData?.rows}
                      isStrictDataForRow={true}
                      isViewMode={true}
                    />
                    <DynamicTableForm
                      columns={res?.customerServicingDetails?.columns}
                      isViewMode={true}
                    />
                  </>
                ))
              ) : (
                <>
                  <DynamicTableForm
                    columns={
                      item?.document.find(
                        (itm) => itm.tableName === "Customer details"
                      )?.columns
                    }
                    tableName={"Customer details"}
                    isViewMode={true}
                  />
                  <DynamicTableForm
                    columns={
                      item?.document.find(
                        (itm) => itm.tableName === "Device Details"
                      )?.columns
                    }
                    tableName={"Device Details"}
                    isViewMode={true}
                  />
                  <DynamicTableForm
                    columns={
                      item?.document.find(
                        (itm) => itm.tableName === "Details of Return"
                      )?.columns
                    }
                    tableName={"Details of Return"}
                    isViewMode={true}
                  />
                  <DynamicTableForm
                    columns={
                      item?.document.find(
                        (itm) => itm.tableName === "Service Engineer Findings"
                      )?.columns
                    }
                    tableName={"Service Engineer Findings"}
                    isViewMode={true}
                  />
                  <Grid item xs={6}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      size="small"
                      value={item?.eSign?.userData?.name}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Time"
                      variant="outlined"
                      size="small"
                      value={
                        item?.eSign?.time
                          ? dayjs(item?.eSign?.time).format("DD/MM/YYYY")
                          : ""
                      }
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              )
            }
          />
        ))
      ) : (
        <Error404
          headingMain="Oops, looks like there are no records!"
          subHeading="Please check back later or create a new record"
        />
      )}
    </>
  );
};

export default withRouter(ServiceRecordHistory);
