import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  FormHelperText,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as url from "./utils/urlHelper";
import { getUserDataByDesignation } from "services/organogramServices";
import {
  extractUserDetails,
  filterUserById,
  getDocumentLifeCycleForMasterDoc,
  getFormatDataById,
  getUserData,
  sendNotification,
  showToast,
  validateColumns,
} from "services/utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ESign from "components/ESign";
import withRouter from "components/withRouter";
import DynamicFormatStructure from "components/reusables/DynamicFormatStructure";
import { setDate } from "date-fns";

const ServiceRecord = (props) => {
  const { params, navigate } = props?.router,
    {
      userData,
      ApiServices,
      Constants,
      TypeConstants,
      DepartmentConstants,
      MasterDocumentTypeConstants,
      FormatConstants,
      routeConstants,
    } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  const [customerDetails, setCustomerDetails] = useState();
  const [customerDetailsColums, setCustomerDetailsColums] = useState();
  const [deviceDetails, setDeviceDetails] = useState();
  const [deviceDetailsColums, setDeviceDetailsColums] = useState();
  const [deviceOfReturn, setDeviceOfReturn] = useState();
  const [deviceOfReturnColums, setDeviceOfReturnColums] = useState();
  const [servicingEngFinding, setServicingEngFinding] = useState();
  const [servicingEngFindingColums, setServicingEngFindingColums] = useState();
  const [errors, setErrors] = useState();
  const { id } = params;

  const getCustomerService = async () => {
    try {
      const uri = url.GET_BY_ID_CUSTOMER + id;
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        getFormatData(result?.data?.document?.columns);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const getFormatData = async (data) => {
    let result = await getFormatDataById(
      FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_FORM
    );
    if (result?.structuredData?.length) {
      let columnData = result?.structuredData;
      // Update logic
      columnData?.forEach((table) => {
        table?.columns?.forEach((column) => {
          const match = data?.find((data) => data?.value === column.value);
          if (match) {
            column.inputValue = match?.inputValue;
          }
        });
      });
      setData(columnData);
      const filteredTable = columnData.find(
        (itm) => itm.tableName === "Customer details"
      );
      setCustomerDetails([filteredTable]);
      setCustomerDetailsColums(filteredTable.columns);
      const filteredTableDD = columnData.find(
        (itm) => itm.tableName === "Device Details"
      );
      setDeviceDetails([filteredTableDD]);
      setDeviceDetailsColums(filteredTableDD.columns);
      const filteredTableDR = columnData.find(
        (itm) => itm.tableName === "Details of Return"
      );
      setDeviceOfReturn([filteredTableDR]);
      setDeviceOfReturnColums(filteredTableDR.columns);
      const filteredTableSEF = columnData.find(
        (itm) => itm.tableName === "Service Engineer Findings"
      );
      setServicingEngFinding([filteredTableSEF]);
      setServicingEngFindingColums(filteredTableSEF.columns);
    }
  };
  useEffect(() => {
    getCustomerService();
  }, []);

  const handleESignUpdate = async (success) => {
    let eSignObj;
    if (success) {
      eSignObj = {
        finalSignBy: {
          time: new Date().toISOString(),
          userData: getUserData(userData),
        },
      };
      setIsOpen(null);
      try {
        let desireArray = [
          {
            tableName: "Customer details",
            columns: customerDetailsColums,
          },
          {
            tableName: "Device Details",
            columns: deviceDetailsColums,
          },
          {
            tableName: "Details of Return",
            columns: deviceOfReturnColums,
          },
          {
            tableName: "Service Engineer Findings",
            columns: servicingEngFindingColums,
          },
        ];
        const masterDocData = await getDocumentLifeCycleForMasterDoc(
          FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_DETAILS,
          MasterDocumentTypeConstants.FORMAT
        );

        let reqObj = {
          masterDocId: masterDocData?._id,
          orgId: userData?.orgId,
          documentName: "Customer Service Record",
          prefixType: "FormatPrefixes",
          eSign: eSignObj?.finalSignBy,
          document: desireArray,
          serviceDetailId: id,
        };
        let uri = url.POST_CUSTOMER_SERVICING;

        const response = await ApiServices.callServicePostWithBodyData(
          uri,
          reqObj
        );

        if (response?.meta?.success) {
          let headUser = await getUserDataByDesignation(
            [
              {
                type: TypeConstants.TYPE_FIND_HEAD,
                department: [
                  DepartmentConstants.SALES_AND_MARKETING_DEPARTMENT,
                ],
              },
            ],
            false
          );
          let notificationObj = {
            dataArray: [
              {
                recipients: filterUserById(headUser),
                msg: "Record has been submited so please share servicing feedback",
                status:
                  "Record has been submited so please share servicing feedback",
                location: `${routeConstants.CUSTOMER_SERVICE}/service-link`,
              },
            ],
          };
          await sendNotification(notificationObj);
          let reqObj = {
            id: id,
            orgId: userData?.orgId,
            documentName: "Customer Servicing Details",
            prefixType: "FormatPrefixes",
            updateData: {
              "document.isReportFiled": true,
            },
          };
          let uri = url.UPDATE_SPECIFIC_DATA;
          const response = await ApiServices.callServicePutWithBodyData(
            uri,
            reqObj
          );
          setTimeout(() => {
            navigate("/");
          }, 1000);
          getCustomerService();
        }
      } catch (error) {
        console.error("Error updating e-signature:", error);
      }
    }
  };

  const onEsign = () => {
    const allColumns = [
      customerDetailsColums,
      deviceDetailsColums,
      deviceOfReturnColums,
      servicingEngFindingColums,
    ];

    let validationErrors = {};
    allColumns.forEach((columns) => {
      const errors = validateColumns(columns);
      if (Object.keys(errors)?.length > 0) {
        validationErrors = { ...validationErrors, ...errors };
      }
    });

    if (Object.keys(validationErrors)?.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsOpen(!isOpen);
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Typography variant="h5" gutterBottom>
        Customer Service Record
      </Typography>

      {isOpen ? (
        <ESign
          open={isOpen}
          setOpen={setIsOpen}
          handleSubmit={handleESignUpdate}
        />
      ) : null}
      <Grid container spacing={2}>
        {data ? (
          <>
            <DynamicFormatStructure
              data={customerDetails}
              columns={customerDetailsColums}
              setColumns={setCustomerDetailsColums}
              setErrors={setErrors}
              errors={errors}
            />
            <DynamicFormatStructure
              data={deviceDetails}
              columns={deviceDetailsColums}
              setColumns={setDeviceDetailsColums}
              setErrors={setErrors}
              errors={errors}
            />
            <DynamicFormatStructure
              data={deviceOfReturn}
              columns={deviceOfReturnColums}
              setColumns={setDeviceOfReturnColums}
              setErrors={setErrors}
              errors={errors}
            />
            <DynamicFormatStructure
              data={servicingEngFinding}
              columns={servicingEngFindingColums}
              setColumns={setServicingEngFindingColums}
              setErrors={setErrors}
              errors={errors}
            />
          </>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Review and Closure of Service Record
        </Typography>
      </Grid>

      <></>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onEsign()}
          // onClick={() => setIsOpen(!isOpen)}
          style={{ marginTop: "10px", marginLeft: "10px" }}
        >
          ESign
        </Button>
      </Grid>
    </Paper>
  );
};

export default withRouter(ServiceRecord);
