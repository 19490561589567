import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CustomDialog from "components/reusables/CustomDialog";
import withRouter from "./withRouter";
import { getYearFromDate, showToast } from "services/utils";
import { routeConstants } from "services/keyConstants";

const GetProduct = (props) => {
  const {
    open,
    setOpen,
    handleSubmit,
    setParentData,
    parentData,
    userData,
    selectedProductData,
  } = props;
  const { navigate } = props?.router;
  const [childProducts, setChildProducts] = useState([]);
  const [parentProduct, setParentProduct] = useState([]);
  const [productIds, setProductIds] = useState({
    parentProductId: "",
    childProductId: "",
  });
  const [selectedData, setSelectedData] = useState({
    parentProduct: "",
    childProduct: "",
  });

  const getProductData = async () => {
    try {
      const reqObj = {
        orgId: userData?.orgId,
      };

      const uri = props?.commonUrl?.GET_PRODUCT_NAME;
      const result = await props?.ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );

      if (result?.meta?.success) {
        setParentProduct(result.data ?? []);
      }
    } catch (error) {
      console.error("ERROR TO GET PRODUCT DATA", error);
    }
  };

  const handleParentProduct = (e) => {
    const { value } = e.target;
    const isProductSelected = selectedProductData?.some(
      (item) => item?.productId === value
    );

    if (!isProductSelected) {
      // If the product is not selected, return early
      showToast("Please create generate quality plan of this product");
      navigate(
        `/${routeConstants.MAM_OF_PRODUCT}/mam-generate-plan/${getYearFromDate(
          new Date()
        )}`
      );

      return;
    } else {
      setSelectedData({
        parentProduct: {
          _id: value,
          productName: parentProduct?.find((item) => item?._id === value)
            ?.productName,
        },
      });
      setParentData(
        parentProduct?.find((item) => item?._id === value)?.productData
      );
      setProductIds({ parentProductId: value });
      getChildData(value);
    }
  };
  const handleChildProduct = (e) => {
    const { value } = e.target;
    setSelectedData({
      ...selectedData,
      childProduct: {
        _id: value,
        productCode: childProducts?.find((item) => item?._id === value)
          ?.productCode,
      },
    });

    setProductIds({ childProductId: value });
  };

  const getChildData = async (parentId) => {
    try {
      const reqObj = {
        orgId: userData?.orgId,
      };
      const uri = props?.commonUrl?.GET_CHILD_PRODUCT + parentId;
      const result = await props?.ApiServices.callServiceGet(uri, reqObj);
      if (result?.meta?.success) {
        setChildProducts(result?.data?.productData ?? []);
      }
    } catch (error) {
      console.error("ERROR TO GET PRODUCT DATA", error);
    }
  };

  const handleModal = () => {
    setOpen(!open);
  };
  useEffect(() => {
    getProductData();
  }, []);

  const handlePasswordSubmit = async () => {
    handleSubmit(selectedData, parentData);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="success"
        onClick={handleModal}
        style={{ marginLeft: "10px" }}
      >
        Select Product
      </Button>
      <CustomDialog open={open} onCancel={(e) => handleModal(e)} maxWidth="sm">
        <DialogTitle>Select Product</DialogTitle>
        <Divider />
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Select parent Product</InputLabel>
            <Select
              value={selectedData?.parentProduct?.productName}
              onChange={(e) => handleParentProduct(e)}
              fullWidth
              displayEmpty
              sx={{ mb: 2 }}
            >
              <MenuItem value="" disabled>
                Select Parent Product
              </MenuItem>
              {parentProduct?.map((product) => (
                <MenuItem key={product?.id} value={product?._id}>
                  {product?.productName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Select Child Product</InputLabel>
            <Select
              value={selectedData?.childProduct?.productCode}
              onChange={(e) => handleChildProduct(e)}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Child Product
              </MenuItem>
              {childProducts?.map((product) => (
                <MenuItem key={product?.id} value={product?._id}>
                  {product?.productCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2.5 }}>
          <Button variant="contained" color="error" onClick={handleModal}>
            Close
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handlePasswordSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};
export default withRouter(GetProduct);
